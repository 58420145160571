import { compact } from 'lodash';
import { MemberBenefits } from '@interfaces/member-benefits.model';
import { MemberPreferredContactMethods } from '@interfaces/member-preferred-contact-methods.model';
import { MemberShoppingHistory } from '@interfaces/member-shopping-history.model';
import { MemberHsaBalance } from '@interfaces/member-hsa-balance.model';
import { MemberDependent } from '@interfaces/member-dependent.model';
import { PreferredAddress } from '@interfaces/preferred-address.model';
import { CareGuidanceEligibility } from '@interfaces/care-guidance-eligibility.model';

export class Member {
  public id: string;
  public member_number: string; // Alias: id
  public subscriber_id: string;
  public account: string;
  public first_name: string;
  public middle_name: string;
  public last_name: string;
  public full_name: string;
  public dob: string;
  public gender: string;
  public address: PreferredAddress;
  public phone_number: string;
  public email_address: string;
  public zip: string;
  public incentives_enabled: boolean;
  public benefits: MemberBenefits[];
  public hsa_balances: MemberHsaBalance[];
  public hsa_balance_enabled: boolean;
  public behavioral_health_eligible: string;
  public internal_plan_id: number;
  public incentive_history: MemberShoppingHistory[]; // TODO: Move out to history component
  public preferred_contact_methods: MemberPreferredContactMethods; // TODO: Move out to preferences component
  public dependents: MemberDependent[];
  public primary: MemberDependent;
  public medicare_advantage: boolean;
  public medical_group_numbers: string[];
  public rx_savings_eligible: boolean;
  public care_guidance: CareGuidanceEligibility;
  public cost_service_member: boolean;
  public quality_rewards_eligible: boolean;
  public future_member: boolean;

  constructor(details: any = {}) {
    this.subscriber_id = details.subscriber_id || null;
    this.id = details.number || null;
    this.member_number = this.id;
    this.first_name = details.first_name || null;
    this.middle_name = details.middle_name || null;
    this.last_name = details.last_name || null;
    this.full_name = this.getFullName();
    this.dob = details.dob || null;
    this.gender = details.gender || null;
    this.address = this.getPreferredAddress(details.addresses);
    this.zip = this.getZipCode(this.address);
    this.phone_number = this.getPreferredPhoneNumber(details.phone_numbers);
    this.email_address = this.getPreferredEmailAddress(details.email_addresses);
    this.behavioral_health_eligible =
      typeof details.behavioral_health_eligible === 'undefined'
        ? null
        : details.behavioral_health_eligible;
    this.internal_plan_id = details.internal_plan_id || null;
    this.medicare_advantage =
      details.medicare_advantage === true ? true : false;
    this.medical_group_numbers = details.medical_group_numbers || null;
    this.setDependents(); // Add as primary dependent
    this.care_guidance = new CareGuidanceEligibility(details?.care_guidance);
    this.cost_service_member = details.cost_service_member || null;
    this.quality_rewards_eligible = details.quality_rewards_eligible;
    this.future_member = details.future_member || false;
    this.incentives_enabled = details.incentives_enabled && !this.future_member
  }

  public setDependents(dependents: any[] = null): Member {
    // Add member to dependents if list is empty
    if (!dependents || !dependents.length) {
      dependents = [
        {
          subscriber_id: this.subscriber_id,
          // eslint-disable-next-line id-blacklist
          number: this.id,
          last_name: this.last_name,
          first_name: this.first_name,
          middle_name: this.middle_name,
          dob: this.dob,
          gender: this.gender,
          behavioral_health_eligible: this.behavioral_health_eligible,
          medical_group_numbers: this.medical_group_numbers,
        },
      ];
    }
    this.dependents = dependents.map((item) => new MemberDependent(item));
    this.dependents[0].isPrimary = true;
    this.primary = this.dependents[0];
    return this;
  }

  public setRxEligible(eligible: boolean): Member {
    this.rx_savings_eligible = eligible && !this.future_member;
    return this;
  }

  public setBenefits(benefits: MemberBenefits[]): Member {
    benefits = benefits || [];
    this.benefits = benefits;
    this.hsa_balances = null;
    this.hsa_balance_enabled = false;
    if (benefits && benefits[0]) {
      this.hsa_balances = benefits[0].hsa_balances;
      this.hsa_balance_enabled = this.getHsaBalanceEnabled(benefits[0]);
    }
    return this;
  }

  public setPreferredContactMethods(contacts: any): Member {
    this.preferred_contact_methods = new MemberPreferredContactMethods(
      contacts
    );
    return this;
  }

  public setIncentiveHistory(history: any): Member {
    this.incentive_history = null;
    if (history) {
      this.incentive_history = history.map(
        (item) => new MemberShoppingHistory(item)
      );
    }
    return this;
  }

  private getHsaBalanceEnabled?(benefits: MemberBenefits): boolean {
    return !!(
      benefits &&
      benefits.hsa_balances &&
      benefits.hsa_balances.length > 0
    );
  }

  private getFullName(): string {
    return compact([this.first_name, this.middle_name, this.last_name]).join(
      ' '
    );
  }

  private getPreferredAddress(addresses: any[]): PreferredAddress {
    addresses = addresses || [];
    const preferred = addresses.find((address) => address.preferred === true);
    const out = preferred ? preferred : addresses[0];
    return out ? new PreferredAddress(out) : null;
  }

  private getPreferredPhoneNumber(phoneNumbers: any[]): string {
    phoneNumbers = phoneNumbers || [];
    return (phoneNumbers[0] && phoneNumbers[0].phone_number) || null;
  }

  private getPreferredEmailAddress(
    emailAddresses: { preferred: boolean; email_address: string }[]
  ) {
    if (!emailAddresses || emailAddresses.length < 1) {
      return null;
    }

    return (
      (emailAddresses.sort(this.compareBoolean('preferred'))[0] || {})[
        'email_address'
      ] || null
    );
  }

  private getZipCode(address: PreferredAddress): string {
    return address ? address.postal_code : null;
  }

  private compareBoolean(key) {
    return (a, b) => (a[key] === b[key] ? 0 : a[key] ? -1 : 1);
  }
}
