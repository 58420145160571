<div class="text-palette-m3-system-gray-10 px-6 md:p-0 md:ml-[148px] max-w-[882px]">

  <div class="mb-10">
    <h3 class="heading-3 font-bold">
      {{pageHeading}}
    </h3>

    <div *ngIf="description" class="text-base mt-2 max-w-[582px]">
      {{description}}
    </div>
  </div>

  <mat-selection-list [multiple]="false">
    <ng-container *ngFor="let plan of plans;  let last = last; let i = index">
      <mat-list-option
        tabindex="0"
        class="h-full py-4"
        (click)="handleSelect(plan)"
        (keydown.enter)="handleSelect(plan)"
        (keydown.space)="handleSelect(plan)"
        [togglePosition]="'before'"
        [value]="plan"
        [selected]="selectedPlan?.displayName === plan.displayName"
        [attr.data-cy]="'mat-list-option-' + i"
        [attr.aria-label]="plan.displayName"
      >
        <ng-container *ngIf="breakpoints.isDesktop; else mobileLayout">
          <div class="flex justify-between items-center">
            <div>
              <p
                class="whitespace-pre-wrap text-medium text-base"
                [innerHTML]="plan.displayName"
              >
              </p>
              <ng-container [ngTemplateOutlet]="planInfo" [ngTemplateOutletContext]="{ plan }"></ng-container>
            </div>
            <div class="ml-2" *ngIf="plan.label">
              <zelis-theme-lozenge
                [emphasis]="'low'"
                [color]="'system-blue'"
                size="medium"
              >
                {{ plan.label.toUpperCase() }}
              </zelis-theme-lozenge>
            </div>
          </div>
        </ng-container>
      </mat-list-option>
      <ng-template #mobileLayout>
        <p
          class="whitespace-pre-wrap text-medium text-base"
          [innerHTML]="plan.displayName"
        >
        </p>
        <div class="my-2" *ngIf="plan.label">
          <zelis-theme-lozenge
            [emphasis]="'low'"
            [color]="'system-blue'"
            size="medium"
          >
            {{ plan.label.toUpperCase() }}
          </zelis-theme-lozenge>
        </div>
        <ng-container [ngTemplateOutlet]="planInfo" [ngTemplateOutletContext]="{ plan }"></ng-container>
      </ng-template>
      <mat-divider *ngIf="!last" [inset]="true" class="m-0 "></mat-divider>
    </ng-container>
  </mat-selection-list>

  <div class="mt-8 flex flex-col sm:items-end">
    <div class="sm:min-w-[240px]">
      <zelis-button
        [fullWidth]="true"
        size="medium"
        variant="flat"
        color="system-blue"
        (clickFn)="handleSubmit()"
        [disabled]="!selectedPlan && plans?.length !== 1"
      >
        {{ 'network_selection_wizard_plan_list_cta' | translate }}
      </zelis-button>
    </div>
    <app-wizard-login-link class="text-center my-4"></app-wizard-login-link>
  </div>
</div>

<ng-template #planInfo let-plan="plan">
  <p
    *ngIf="plan.related_plans"
    class="whitespace-pre-wrap text-palette-m3-system-gray-35"
    [innerHTML]="plan.related_plans"
    matListItemLine
  ></p>
  <p
    class="whitespace-pre-wrap text-palette-m3-system-gray-10"
    *ngIf="plan.description"
    [innerHTML]="plan.description"
    matListItemLine
  ></p>
</ng-template>
