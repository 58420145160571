<zelis-lozenge
  [size]="size"
  [anchor]="anchor"
  [type]="type"
  [ngStyleExpr]="ngStyleExpr"
  [classes]="classes"
  [icon]="icon"
>
  <ng-content></ng-content>
</zelis-lozenge>
