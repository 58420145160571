import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as SearchActions from './serp-summary.actions';
import { map, switchMap, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { SerpService } from '@services/serp/serp.service';
import { of } from 'rxjs';
import { Results } from '@interfaces/results.interface';
import { AuthStoreSelectors } from '@store/auth';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state/app.state';
import { resetRatesResults } from '@store/rates-serp/serp-summary/serp-summary.actions'
import { UpdateSelectedBillingCodeDependents } from '@store/billing-code-search/billing-code.actions'
import { getSearchType } from '@store/search/search.selectors';
import { getConfigFilters } from '@store/search-filters-v2/search-filters-v2.selectors';
import { SearchFiltersV2Service } from '@services/search/search-filters-v2.service';
import { AuthStatus } from '@interfaces/auth-status.model';
import { SearchFilterV2 } from '@interfaces/search-filter-v2.model';
import { SearchParams } from '@interfaces/search-params.model';
import { cloneDeep } from 'lodash';
import { AppointmentService } from '@services/appointment.service';

@Injectable()
export class SerpResultsSummaryEffects {
  getSerpResultsSummary = createEffect(() =>
    this.actions.pipe(
      ofType(SearchActions.requestRatesResults),
      withLatestFrom(
        this.store.select(AuthStoreSelectors.getAuthStatus),
        this.store.select(getSearchType),
        this.store.select(getConfigFilters)
          .pipe(filter((configFilters) => !!configFilters))
      ),
      switchMap(([action, auth, searchParamType, configFilters]) => {
        return this.searchFiltersV2Service.checkConfigToHideOrDisable(
          searchParamType,
          action.search_params,
          cloneDeep(configFilters)
        ).pipe(map((searchFilters) => [action.search_params, auth, searchFilters]))
      }),
      switchMap(([searchParams, auth, searchFilters]: [SearchParams, AuthStatus, SearchFilterV2[]]) => {
        if (auth.resolved && auth.auth_status) {
          return this.serpSummaryService
            .requestSerpSummary(
              searchParams,
              searchFilters
            )
            .pipe(
              map((results: Results) =>
                SearchActions.requestRatesResultsSuccess({
                  results: results,
                })
              ),
              catchError((error) =>
                of(
                  SearchActions.requestRatesResultsFailure({
                    error,
                  })
                )
              )
            );
        }
      })
    )
  );

  dispatchClearSummaryResults = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateSelectedBillingCodeDependents),
      map(() => resetRatesResults())
    )
  );

  getAppointmentAvailability = createEffect(() =>
    this.actions.pipe(
      ofType(SearchActions.requestRatesResultsSuccess),
      switchMap((response) => {
        return this.appointmentService.getAvailabilityByProviders(response.results.providers).pipe(
          filter((availabilityResponse: any) => availabilityResponse.availabilities_by_providers),
          map((availabilityResponse: any) => {
            const updatedProviders = this.appointmentService.updateProvidersWithAvailability(
              response.results.providers,
              availabilityResponse.availabilities_by_providers
            );

            return {
              ...response.results,
              providers: updatedProviders
            }
          })
        );
      }),
      map((results: Results) => {
        return SearchActions.requestAppointmentAvailabilitiesSuccess({results: results});
      }),
      catchError(error => of(SearchActions.requestAppointmentAvailabilitiesFailure({error})))
    )
  );

  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private serpSummaryService: SerpService,
    private searchFiltersV2Service: SearchFiltersV2Service,
    private appointmentService: AppointmentService
  ) {}
}
