<!-- Add intended provider -->
<div class="intended-providers-card">
  <div
    class="pad all-2x"
    *ngIf="!show && !bvaService.providers?.intended?.name && results"
  >
    <a
      (click)="goToChooseProviderStep()"
      data-cy="intended-providers.add-providers"
      >{{ 'bva_add_intended_provider' | translate }}</a
    >
  </div>

  <!-- Slides -->
  <div class="pad all-2x" *ngIf="show && results">
    <div *ngIf="active === 'stepOne'" class="step-one">
      <form [formGroup]="form">
        <div>
          <h4>{{ 'provider_referral_already_in_mind' | translate }}</h4>
        </div>
        <div class="action-buttons margin top-3x">
          <button
            (click)="goToChooseProviderStep()"
            mat-flat-button
            color="accent"
            type="submit"
            class="platform-button margin right-1x"
          >
            {{ 'provider_referral_yes' | translate }}
          </button>
          <button
            mat-flat-button
            color="accent"
            type="button"
            class="platform-button"
            data-cy="intended-providers.no-button"
            (click)="hasNoProvider()"
          >
            {{ 'provider_referral_no' | translate }}
          </button>
        </div>
      </form>
    </div>

    <div
      class="choose-provider"
      data-cy="intended-providers.choose-provider"
      *ngIf="active === 'chooseProvider'"
      fxLayout="column"
    >
      <div>
        <h3 class="pad bottom-3x">
          {{ 'provider_referral_plan' | translate }}
          <span class="text-bold">{{ procedure }}</span
          >?
        </h3>
        <div class="autocomplete">
          <form [formGroup]="form" autocomplete="off" novalidate>
            <mat-form-field
              fxFlex="100"
              fxFlexAlign="center"
              floatLabel="auto"
            >
              <input
                matInput
                type="text"
                placeholder="{{ 'provider_referral_facility' | translate }}"
                formControlName="facility"
                [matAutocomplete]="facilityAuto"
                data-cy="intended-providers.input"
              />
              <zelis-progress-spinner
                matSuffix
                *ngIf="facilitySearching"
                [diameter]="28"
                [strokeWidth]="2"
              >
              </zelis-progress-spinner>
            </mat-form-field>
            <mat-autocomplete
              class="intended-provider-autocomplete"
              #facilityAuto="matAutocomplete"
              [displayWith]="facilityDisplay"
              (optionSelected)="handleSelectedFacility($event.option.value)"
            >
              <mat-option
                *ngFor="let result of filteredOptions | async; let i = index"
                class="two-line"
                [attr.data-cy]="'intended-providers.option-' + i"
                [value]="result"
              >
                <div class="facility_name">{{ result?.name }}</div>
                <div class="facility_address">
                  {{ result?.addr_line1 }} {{ result?.addr_line2 }}
                  {{ result?.city }}, {{ result?.state }}
                  {{ result?.postal_code }}
                </div>
              </mat-option>
              <mat-option
                class="provider-not-seen clickable"
                data-cy="intended-providers.not-seen-option"
                fxLayout="row"
                fxFlex="100%"
                fxLayoutAlign="space-between center"
                (click)="goToChooseProviderStep()"
              >
                {{ 'provider_referral_unfound_facility' | translate }}
                <mat-icon svgIcon="dls-angle-plain-right-light"></mat-icon>
              </mat-option>
            </mat-autocomplete>
          </form>
        </div>
      </div>
      <div fxFlex="100%" fxLayoutAlign="center" fxLayoutGap="10px">
        <button
          mat-button
          class="skip"
          data-cy="intended-providers.skip"
          type="button"
          (click)="close()"
          [attr.aria-label]="'provider_referral_skip' | translate"
        >
          {{ 'provider_referral_skip' | translate }}
        </button>
      </div>
    </div>

    <!-- TODO: See if this is still needed. allowProviderSelect and stepOneEnabled not defined. -->
    <!-- <div *ngIf="allowProviderSelect" class="dots">
      <span *ngIf="stepOneEnabled" [ngClass]="{active: active === 'stepOne'}"></span>
      <span [ngClass]="{active: active === 'chooseProvider'}"></span>
    </div> -->
  </div>
</div>
