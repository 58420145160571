import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import type { IconName } from '@zelis/dls/icons';

type AvatarElevationScale =
  | '0dp'
  | '1dp'
  | '2dp'
  | '4dp'
  | '6dp'
  | '8dp'
  | '12dp'
  | '16dp'
  | '20dp'
  | '24dp';
type AvatarElevation = 'auto' | AvatarElevationScale;
type AvatarSizeScale =
  | '32px'
  | '40px'
  | '48px'
  | '56px'
  | '64px'
  | '72px'
  | '80px'
  | '88px'
  | '96px'
  | '104px'
  | '112px'
  | '120px'
  | '128px'
  | '136px'
  | '144px'
  | '152px'
  | '160px';
type AvatarSize =
  | 'auto'
  // Alias for 48px. Kept for backwards compatibility.
  | 'small'
  // Alias for 64px. Kept for backwards compatibility.
  | 'large'
  | AvatarSizeScale;

@Component({
  selector: 'zelis-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarComponent implements OnChanges {
  @HostBinding() class = 'zelis-dls';

  // This should default to 'auto' but it would be a breaking change
  @Input() elevation: AvatarElevation = '0dp';
  @Input() placeholderIcon?: IconName;
  @Input() isProviderAvatar?: boolean;
  @Input() size: AvatarSize = 'auto';
  @Input() src?: string;
  @Input() text?: string;
  @Input() ariaLabel? = 'Avatar';

  isImageLoaded = false;
  hasError = false;

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.src && this.text) {
      throw new Error(
        'You can only set one of "src" and "text", but not both.'
      );
    }
    if (this.src && !this.placeholderIcon) {
      throw new Error('If "src" is set, "placeholderIcon" must be provided.');
    }

    if (simpleChanges['src']) {
      this.isImageLoaded = false;
      this.src = this.src
        ?.replace(/ /g, '%20')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29');

      if (this.src === this.placeholderIcon) {
        this.src = undefined;
      }
    }
  }

  isElevation(elevation: AvatarElevation): boolean {
    return this.elevation === elevation;
  }

  isSize(size: AvatarSize): boolean {
    return this.size === size;
  }

  onImageLoad() {
    this.isImageLoaded = true;
    this.hasError = false;
  }

  onImageError() {
    this.hasError = true;
    this.isImageLoaded = false;
  }
}
