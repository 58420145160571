import { Injectable } from '@angular/core';
import { SnackbarComponent, SnackBarConfig } from '../..';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';

const DEFAULT_DISMISSAL_DURATION = 4000;
@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private snackBarQue: SnackBarConfig<any>[] = [];
  constructor(private snackbar: MatSnackBar) {}

  public open(config: SnackBarConfig): void {
    if (this.snackbar._openedSnackBarRef) {
      this.snackBarQue.push(config);
      return;
    }
    const transformedConfig = this.transformConfig(config);
    const snackbarRef = this.snackbar.openFromComponent(
      SnackbarComponent,
      transformedConfig
    );
    this.listenToDismissal(snackbarRef);
  }

  private transformConfig(config: SnackBarConfig): SnackBarConfig {
    config.panelClass = ['bg-palette-m3-system-gray-10', 'max-w-4xl'];
    config.horizontalPosition = 'left';
    config.duration = this.setDuration(config);
    return config;
  }

  private setDuration(config: SnackBarConfig): number | undefined {
    const { data } = config;
    return data.cta || data.closeBtn
      ? undefined
      : config.duration || DEFAULT_DISMISSAL_DURATION;
  }

  private listenToDismissal(ref: MatSnackBarRef<any>): void {
    ref
      .afterDismissed()
      .pipe(take(1))
      .subscribe(() => {
        const config = this.snackBarQue.shift();
        if (config) {
          this.open(config);
        }
      });
  }
}
