import { SearchFilterV2MultiType, SearchFilterV2Type } from '@interfaces/search-filter-v2-type.interface';
import { TranslateService } from '@ngx-translate/core';
import { SearchFilterV2 } from '@interfaces/search-filter-v2.model';
import { cloneDeep, forEach, sortBy } from 'lodash';
import { SelectedFilter } from '@interfaces/selected-filter.interface';

export class SearchFilterV2Dls {
  facet: string;
  name: string;
  description?: string;
  options?: SearchFilterV2OptionDls[];
  selectedCount?: number;
  type?: SearchFilterV2Type;
  group: 'main' | 'more';
  multiType?: SearchFilterV2MultiType;
  default?: string[];
// 'N' is truthy for virtual_only
// 'F,Y,EO,OE,OX,O,XO' is truthy for contract_accepting_new_patients
  private truthyToggleValues = ['F,Y,EO,OE,OX,O,XO', 'Y', 'true', 'N'];

  constructor(obj: SearchFilterV2, translateService: TranslateService, selectedFilters) {
    this.name = translateService.instant(obj.name);
    this.description = obj.description && translateService.instant(obj.description);
    this.facet = obj.facet;
    this.multiType = obj.type?.split('#')[1] as SearchFilterV2MultiType;
    this.type = obj.type?.split('#')[0] as SearchFilterV2Type;
    this.options = obj.options ? this.mapOptions(obj, translateService, selectedFilters): [];
    this.selectedCount = this.setSelectedCount(this.options);
    this.group = obj.group;
    this.default = obj.default || [];
  }

  private setSelectedCount(options): number {
    return options
      ?.reduce((a: any, b: any) => a.concat(b), [])
      .filter((option: SearchFilterV2OptionDls) => option.selected).length;
  }

  private mapOptions(
    obj: SearchFilterV2,
    translateService: TranslateService,
    selectedFilters: SelectedFilter
  ): SearchFilterV2OptionDls[] {
    const selectedFilterValue = selectedFilters[obj.facet];
    const clonedOptions = cloneDeep(obj.options);
    // Checks selected filters and sets selected property if the option value matches
    if (!!selectedFilterValue) {
      if (obj.type === 'toggle' && this.truthyToggleValues.includes(selectedFilterValue)) {
        const match = clonedOptions.find((opt) => opt['value'] || opt['value'] === selectedFilterValue);
        if (match) {
          match.selected = true;
        }
      } else {
        // Multi select values use | as separator
        const values: string[] = selectedFilterValue.split('|');
        clonedOptions.forEach(option => {
          option.selected = values.includes(option.value);
        })
      }
    }
    let options = clonedOptions.map(option => ({
      ...option,
      name: typeof option.name === 'string' ? translateService.instant(option.name) : option.name,
    }))
    
    obj.subcategories ? options = this.alphabetizeSubcategoryOptions(options) : options;
    return options;
  }

  private alphabetizeSubcategoryOptions(options: SearchFilterV2OptionDls[]): SearchFilterV2OptionDls[] {
    const sortedList = [];
    let currentSection = [];

    forEach(options, (option) => {
      if (option.type === 'subcategory') {
        // Sort top section, add subcategory header 
        sortedList.push(...sortBy(currentSection, 'name'));
        sortedList.push(option);
        currentSection = [];
      } else {
        currentSection.push(option);
      }
    });
    
    // Sort and add subcategory section
    sortedList.push(...sortBy(currentSection, 'name'));
    return sortedList;
  }
}

export interface SearchFilterV2OptionDls {
  name: string;
  value: string;
  heading?: string;
  selected: boolean;
  disabled?: boolean;
  type?: string;
}
