import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  Input,
} from '@angular/core';
import { coerceBoolean } from 'coerce-property';

@Component({
  selector: 'zelis-dialog-header',
  templateUrl: './dialog-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  @HostBinding('class')
  get hostClasses(): string[] {
    let classes = ['zelis-dls'];
    if (this.separator) {
      classes = [
        ...classes,
        ...[
          'border-b',
          'border-palette-m3-system-gray-85',
          'px-4',
          'sm:px-6',
          'py-6',
          '-mt-6',
          '-mx-6',
        ],
      ];
    }
    return classes;
  }

  @Input() title?: string;
  @Input() titleClass = 'heading-4 tracking-normal';
  @Input() caption?: string;
  @Input() dataCyBase?: string;
  @Input() closeButtonLabel = 'close button';
  @Input()
  @coerceBoolean
  dismissable!: boolean;
  @Input() @coerceBoolean separator = false;

  get titleCy() {
    return this['dataCyBase'] ? `${this['dataCyBase']}-title` : '';
  }

  get captionCy() {
    return this['dataCyBase'] ? `${this['dataCyBase']}-caption` : '';
  }
  get closeCy() {
    return this['dataCyBase'] ? `${this['dataCyBase']}-close` : '';
  }
}
