/**
 * I was hoping to type this as a template literal type (e.g. type AssetIconsSVGPath = `assets/icons/${string}.svg`)
 * but compodoc does not support them so have to go for simple string for now.
 */
type AssetIconsSVGPath = string;

interface Icon {
  name: string;
  path: AssetIconsSVGPath;
}

export interface SVGGrouping {
  name: string;
  icons: Icon[];
}

export const svgGroupings = [
  {
    name: 'ambulance-light',
    icons: [
      {
        name: 'dls-ambulance-light',
        path: 'assets/icons/ambulance-light.svg',
      },
    ],
  },
  {
    name: 'angle',
    icons: [
      {
        name: 'dls-angle-circle-left-light',
        path: 'assets/icons/angle-circle-left-light.svg',
      },
      {
        name: 'dls-angle-circle-right-light',
        path: 'assets/icons/angle-circle-right-light.svg',
      },
      {
        name: 'dls-angle-plain-down-light',
        path: 'assets/icons/angle-plain-down-light.svg',
      },
      {
        name: 'dls-angle-plain-left-light',
        path: 'assets/icons/angle-plain-left-light.svg',
      },
      {
        name: 'dls-angle-plain-right-light',
        path: 'assets/icons/angle-plain-right-light.svg',
      },
      {
        name: 'dls-angle-plain-up-light',
        path: 'assets/icons/angle-plain-up-light.svg',
      },
    ],
  },
  {
    name: 'arrow',
    icons: [
      {
        name: 'dls-arrow-circle-arrow-left-light',
        path: 'assets/icons/arrow-circle-arrow-left-light.svg',
      },
      {
        name: 'dls-arrow-circle-arrow-right-light',
        path: 'assets/icons/arrow-circle-arrow-right-light.svg',
      },
      {
        name: 'dls-arrow-down-light',
        path: 'assets/icons/arrow-down-light.svg',
      },
      {
        name: 'dls-arrow-left-light',
        path: 'assets/icons/arrow-left-light.svg',
      },
      {
        name: 'dls-arrow-right-light',
        path: 'assets/icons/arrow-right-light.svg',
      },
      {
        name: 'dls-arrow-up-light',
        path: 'assets/icons/arrow-up-light.svg',
      },
      {
        name: 'dls-arrow-right-from-bracket-light',
        path: 'assets/icons/arrow-right-from-bracket-light.svg',
      },
    ],
  },
  {
    name: 'arrow-rotate-right-light',
    icons: [
      {
        name: 'dls-arrow-rotate-right-light',
        path: 'assets/icons/arrow-rotate-right-light.svg',
      },
    ],
  },
  {
    name: 'award-light',
    icons: [
      {
        name: 'dls-award-light',
        path: 'assets/icons/award-light.svg',
      },
      {
        name: 'dls-award-simple-light',
        path: 'assets/icons/award-simple-light.svg',
      },
    ],
  },
  {
    name: 'baby-light',
    icons: [
      {
        name: 'dls-baby-light',
        path: 'assets/icons/baby-light.svg',
      },
    ],
  },
  {
    name: 'bell',
    icons: [
      {
        name: 'dls-bell-on-light',
        path: 'assets/icons/bell-on-light.svg',
      },
      {
        name: 'dls-bell-plain-light',
        path: 'assets/icons/bell-plain-light.svg',
      },
      {
        name: 'dls-bell-slash-light',
        path: 'assets/icons/bell-slash-light.svg',
      },
    ],
  },
  {
    name: 'bolt',
    icons: [
      {
        name: 'dls-bolt-light',
        path: 'assets/icons/bolt-light.svg',
      },
    ],
  },
  {
    name: 'book-light',
    icons: [
      {
        name: 'dls-book-light',
        path: 'assets/icons/book-light.svg',
      },
    ],
  },
  {
    name: 'bookmark',
    icons: [
      {
        name: 'dls-bookmark-plain-light',
        path: 'assets/icons/bookmark-plain-light.svg',
      },
      {
        name: 'dls-bookmark-slash-light',
        path: 'assets/icons/bookmark-slash-light.svg',
      },
    ],
  },
  {
    name: 'brain',
    icons: [
      {
        name: 'dls-brain-light',
        path: 'assets/icons/brain-light.svg',
      },
      {
        name: 'dls-brain-head-side-light',
        path: 'assets/icons/brain-head-side-light.svg',
      },
    ],
  },
  {
    name: 'briefcase-medical-light',
    icons: [
      {
        name: 'dls-briefcase-medical-light',
        path: 'assets/icons/briefcase-medical-light.svg',
      },
    ],
  },
  {
    name: 'bullseye-arrow',
    icons: [
      {
        name: 'dls-bullseye-arrow-light',
        path: 'assets/icons/bullseye-arrow-light.svg',
      },
    ],
  },
  {
    name: 'calendar',
    icons: [
      {
        name: 'dls-calendar-check-light',
        path: 'assets/icons/calendar-check-light.svg',
      },
      {
        name: 'dls-calendar-days-light',
        path: 'assets/icons/calendar-days-light.svg',
      },
      {
        name: 'dls-calendar-plain-light',
        path: 'assets/icons/calendar-plain-light.svg',
      },
      {
        name: 'dls-calendar-plus-light',
        path: 'assets/icons/calendar-plus-light.svg',
      },
    ],
  },
  {
    name: 'caret',
    icons: [
      {
        name: 'dls-caret-down-solid',
        path: 'assets/icons/caret-down-solid.svg',
      },
      {
        name: 'dls-caret-left-solid',
        path: 'assets/icons/caret-left-solid.svg',
      },
      {
        name: 'dls-caret-right-solid',
        path: 'assets/icons/caret-right-solid.svg',
      },
      {
        name: 'dls-caret-up-solid',
        path: 'assets/icons/caret-up-solid.svg',
      },
    ],
  },
  {
    name: 'check',
    icons: [
      {
        name: 'dls-check-circle-light',
        path: 'assets/icons/check-circle-light.svg',
      },
      {
        name: 'dls-check-circle-solid',
        path: 'assets/icons/check-circle-solid.svg',
      },
      {
        name: 'dls-check-plain-light',
        path: 'assets/icons/check-plain-light.svg',
      },
      {
        name: 'dls-check-square-light',
        path: 'assets/icons/check-square-light.svg',
      },
    ],
  },
  {
    name: 'clipboard',
    icons: [
      {
        name: 'dls-clipboard-check-light',
        path: 'assets/icons/clipboard-check-light.svg',
      },
      {
        name: 'dls-clipboard-list-check-light',
        path: 'assets/icons/clipboard-list-check-light.svg',
      },
      {
        name: 'dls-clipboard-list-light',
        path: 'assets/icons/clipboard-list-light.svg',
      },
      {
        name: 'dls-clipboard-medical-light',
        path: 'assets/icons/clipboard-medical-light.svg',
      },
      {
        name: 'dls-clipboard-plain-light',
        path: 'assets/icons/clipboard-plain-light.svg',
      },
      {
        name: 'dls-clipboard-prescription-light',
        path: 'assets/icons/clipboard-prescription-light.svg',
      },
      {
        name: 'dls-clipboard-user-light',
        path: 'assets/icons/clipboard-user-light.svg',
      },
    ],
  },
  {
    name: 'clock-light',
    icons: [
      {
        name: 'dls-clock-light',
        path: 'assets/icons/clock-light.svg',
      },
      {
        name: 'dls-clock-rotate-left-light',
        path: 'assets/icons/clock-rotate-left-light.svg',
      },
    ],
  },
  {
    name: 'comments',
    icons: [
      {
        name: 'dls-comment-check-light',
        path: 'assets/icons/comment-check-light.svg',
      },
      {
        name: 'dls-comment-question-light',
        path: 'assets/icons/comment-question-light.svg',
      },
      {
        name: 'dls-comments-comment-lines-light',
        path: 'assets/icons/comments-comment-lines-light.svg',
      },
      {
        name: 'dls-comments-comment-light',
        path: 'assets/icons/comments-comment-light.svg',
      },
      {
        name: 'dls-comments-light',
        path: 'assets/icons/comments-light.svg',
      },
    ],
  },
  {
    name: 'compass-light',
    icons: [
      {
        name: 'dls-compass-light',
        path: 'assets/icons/compass-light.svg',
      },
    ],
  },
  {
    name: 'computer',
    icons: [
      {
        name: 'dls-computer-desktop-display-light',
        path: 'assets/icons/computer-desktop-display-light.svg',
      },
      {
        name: 'dls-computer-laptop-light',
        path: 'assets/icons/computer-laptop-light.svg',
      },
      {
        name: 'dls-computer-laptop-mobile-light',
        path: 'assets/icons/computer-laptop-mobile-light.svg',
      },
    ],
  },
  {
    name: 'concierge-bell-light',
    icons: [
      {
        name: 'dls-concierge-bell-light',
        path: 'assets/icons/concierge-bell-light.svg',
      },
    ],
  },
  {
    name: 'crown',
    icons: [
      {
        name: 'dls-crown-light',
        path: 'assets/icons/crown-light.svg',
      },
    ],
  },
  {
    name: 'doctor',
    icons: [
      {
        name: 'dls-doctor-long-hair-light',
        path: 'assets/icons/doctor-long-hair-light.svg',
      },
      {
        name: 'dls-doctor-multiple-doctors-light',
        path: 'assets/icons/doctor-multiple-doctors-light.svg',
      },
      {
        name: 'dls-doctor-nurse-or-surgeon no hair-light',
        path: 'assets/icons/doctor-nurse-or-surgeon no hair-light.svg',
      },
      {
        name: 'dls-doctor-nurse-with-long hair-light',
        path: 'assets/icons/doctor-nurse-with-long hair-light.svg',
      },
      {
        name: 'dls-doctor-plain-light',
        path: 'assets/icons/doctor-plain-light.svg',
      },
      {
        name: 'dls-doctor-some-hair-light',
        path: 'assets/icons/doctor-some-hair-light.svg',
      },
    ],
  },
  {
    name: 'dollar-signs',
    icons: [
      {
        name: 'dls-dollar-signs-circle-light',
        path: 'assets/icons/dollar-signs-circle-light.svg',
      },
      {
        name: 'dls-dollar-signs-circle-solid',
        path: 'assets/icons/dollar-signs-circle-solid.svg',
      },
      {
        name: 'dls-dollar-signs-envelope-light',
        path: 'assets/icons/dollar-signs-envelope-light.svg',
      },
      {
        name: 'dls-dollar-signs-hand-outstretched-light',
        path: 'assets/icons/dollar-signs-hand-outstretched-light.svg',
      },
      {
        name: 'dls-dollar-signs-hands-holding-light',
        path: 'assets/icons/dollar-signs-hands-holding-light.svg',
      },
      {
        name: 'dls-dollar-signs-plain-light',
        path: 'assets/icons/dollar-signs-plain-light.svg',
      },
      {
        name: 'dls-dollar-signs-sack-light',
        path: 'assets/icons/dollar-signs-sack-light.svg',
      },
      {
        name: 'dls-dollar-signs-square-light',
        path: 'assets/icons/dollar-signs-square-light.svg',
      },
    ],
  },
  {
    name: 'download-light',
    icons: [
      {
        name: 'dls-download-light',
        path: 'assets/icons/download-light.svg',
      },
    ],
  },
  {
    name: 'draw-circle',
    icons: [
      {
        name: 'dls-draw-circle-light',
        path: 'assets/icons/draw-circle-light.svg',
      },
    ],
  },
  {
    name: 'edit',
    icons: [
      {
        name: 'dls-edit-circle-light',
        path: 'assets/icons/edit-circle-light.svg',
      },
      {
        name: 'dls-edit-plain-light',
        path: 'assets/icons/edit-plain-light.svg',
      },
    ],
  },
  {
    name: 'education',
    icons: [
      {
        name: 'dls-education-graduate-light',
        path: 'assets/icons/education-graduate-light.svg',
      },
      {
        name: 'dls-education-graduation-cap-light',
        path: 'assets/icons/education-graduation-cap-light.svg',
      },
    ],
  },
  {
    name: 'ellipsis-light',
    icons: [
      {
        name: 'dls-ellipsis-light',
        path: 'assets/icons/ellipsis-light.svg',
      },
    ],
  },
  {
    name: 'envelope-light',
    icons: [
      {
        name: 'dls-envelope-light',
        path: 'assets/icons/envelope-light.svg',
      },
    ],
  },
  {
    name: 'equals-light',
    icons: [
      {
        name: 'dls-equals-light',
        path: 'assets/icons/equals-light.svg',
      },
    ],
  },
  {
    name: 'exclamation',
    icons: [
      {
        name: 'dls-exclamation-circle-light',
        path: 'assets/icons/exclamation-circle-light.svg',
      },
      {
        name: 'dls-exclamation-circle-solid',
        path: 'assets/icons/exclamation-circle-solid.svg',
      },
      {
        name: 'dls-exclamation-plain-light',
        path: 'assets/icons/exclamation-plain-light.svg',
      },
      {
        name: 'dls-exclamation-square-light',
        path: 'assets/icons/exclamation-square-light.svg',
      },
    ],
  },
  {
    name: 'external-link-light',
    icons: [
      {
        name: 'dls-external-link-light',
        path: 'assets/icons/external-link-light.svg',
      },
    ],
  },
  {
    name: 'eye',
    icons: [
      {
        name: 'dls-eye-light',
        path: 'assets/icons/eye-light.svg',
      },
      {
        name: 'dls-eye-slash-light',
        path: 'assets/icons/eye-slash-light.svg',
      },
    ],
  },
  {
    name: 'face',
    icons: [
      {
        name: 'dls-face-laugh-beam-light',
        path: 'assets/icons/face-laugh-beam-light.svg',
      },
    ],
  },
  {
    name: 'facility',
    icons: [
      {
        name: 'dls-facility-building-light',
        path: 'assets/icons/facility-building-light.svg',
      },
      {
        name: 'dls-facility-hospital-light',
        path: 'assets/icons/facility-hospital-light.svg',
      },
      {
        name: 'dls-facility-medical-home-light',
        path: 'assets/icons/facility-medical-home-light.svg',
      },
      {
        name: 'dls-facility-1',
        path: 'assets/icons/facility-1.svg',
      },
      {
        name: 'dls-facility-2',
        path: 'assets/icons/facility-2.svg',
      },
      {
        name: 'dls-facility-3',
        path: 'assets/icons/facility-3.svg',
      },
    ],
  },
  {
    name: 'file',
    icons: [
      {
        name: 'dls-file-check-light',
        path: 'assets/icons/file-check-light.svg',
      },
      {
        name: 'dls-file-dollar-light',
        path: 'assets/icons/file-dollar-light.svg',
      },
      {
        name: 'dls-file-plain-light',
        path: 'assets/icons/file-plain-light.svg',
      },
    ],
  },
  {
    name: 'filter',
    icons: [
      {
        name: 'dls-filter-light',
        path: 'assets/icons/filter-light.svg',
      },
    ],
  },
  {
    name: 'flag-swallowtail',
    icons: [
      {
        name: 'dls-flag-swallowtail-light',
        path: 'assets/icons/flag-swallowtail-light.svg',
      },
    ],
  },
  {
    name: 'gear-light',
    icons: [
      {
        name: 'dls-gear-light',
        path: 'assets/icons/gear-light.svg',
      },
    ],
  },
  {
    name: 'gender',
    icons: [
      {
        name: 'dls-gender-mars-light',
        path: 'assets/icons/gender-mars-light.svg',
      },
      {
        name: 'dls-gender-venus-light',
        path: 'assets/icons/gender-venus-light.svg',
      },
    ],
  },
  {
    name: 'gift-light',
    icons: [
      {
        name: 'dls-gift-light',
        path: 'assets/icons/gift-light.svg',
      },
    ],
  },
  {
    name: 'globe-light',
    icons: [
      {
        name: 'dls-globe-light',
        path: 'assets/icons/globe-light.svg',
      },
    ],
  },
  {
    name: 'hamburger-light',
    icons: [
      {
        name: 'dls-hamburger-light',
        path: 'assets/icons/hamburger-light.svg',
      },
    ],
  },
  {
    name: 'hands',
    icons: [
      {
        name: 'dls-hand-holding-seedling-light',
        path: 'assets/icons/hand-holding-seedling-light.svg',
      },
      {
        name: 'dls-hands-hand-holding-heart-light',
        path: 'assets/icons/hands-hand-holding-heart-light.svg',
      },
      {
        name: 'dls-hands-hand-holding-light',
        path: 'assets/icons/hands-hand-holding-light.svg',
      },
      {
        name: 'dls-handshake-light',
        path: 'assets/icons/handshake-light.svg',
      },
    ],
  },
  {
    name: 'headset',
    icons: [
      {
        name: 'dls-headset-light',
        path: 'assets/icons/headset-light.svg',
      },
      {
        name: 'dls-headset-user-headset-light',
        path: 'assets/icons/headset-user-headset-light.svg',
      },
    ],
  },
  {
    name: 'heart-rate-light',
    icons: [
      {
        name: 'dls-heart-rate-light',
        path: 'assets/icons/heart-rate-light.svg',
      },
    ],
  },
  {
    name: 'home-light',
    icons: [
      {
        name: 'dls-home-light',
        path: 'assets/icons/home-light.svg',
      },
    ],
  },
  {
    name: 'hospital-circle-light',
    icons: [
      {
        name: 'dls-hospital-circle-light',
        path: 'assets/icons/hospital-circle-light.svg',
      },
    ],
  },
  {
    name: 'id-card-light',
    icons: [
      {
        name: 'dls-id-card-light',
        path: 'assets/icons/id-card-light.svg',
      },
    ],
  },
  {
    name: 'illustration-compare',
    icons: [
      {
        name: 'dls-illustration-compare',
        path: 'assets/icons/illustration-compare.svg',
      },
    ],
  },
  {
    name: 'illustration-earn',
    icons: [
      {
        name: 'dls-illustration-earn',
        path: 'assets/icons/illustration-earn.svg',
      },
    ],
  },
  {
    name: 'illustration-facility',
    icons: [
      {
        name: 'dls-illustration-facility',
        path: 'assets/icons/illustration-facility.svg',
      },
    ],
  },
  {
    name: 'illustration-medication',
    icons: [
      {
        name: 'dls-illustration-medication',
        path: 'assets/icons/illustration-medication.svg',
      },
    ],
  },
  {
    name: 'illustration-schedule-calendar',
    icons: [
      {
        name: 'dls-illustration-schedule-calendar',
        path: 'assets/icons/illustration-schedule-calendar.svg',
      },
    ],
  },
  {
    name: 'illustration-stethoscope',
    icons: [
      {
        name: 'dls-illustration-stethoscope',
        path: 'assets/icons/illustration-stethoscope.svg',
      },
    ],
  },
  {
    name: 'inbox_light',
    icons: [
      {
        name: 'dls-inbox_light',
        path: 'assets/icons/inbox_light.svg',
      },
    ],
  },
  {
    name: 'info',
    icons: [
      {
        name: 'dls-info-circle-light',
        path: 'assets/icons/info-circle-light.svg',
      },
      {
        name: 'dls-info-circle-solid',
        path: 'assets/icons/info-circle-solid.svg',
      },
      {
        name: 'dls-info-plain-light',
        path: 'assets/icons/info-plain-light.svg',
      },
      {
        name: 'dls-info-square-light',
        path: 'assets/icons/info-square-light.svg',
      },
    ],
  },
  {
    name: 'layer-group-light',
    icons: [
      {
        name: 'dls-layer-group-light',
        path: 'assets/icons/layer-group-light.svg',
      },
    ],
  },
  {
    name: 'lightbulb',
    icons: [
      {
        name: 'dls-lightbulb-light',
        path: 'assets/icons/lightbulb-light.svg',
      },
    ],
  },
  {
    name: 'link',
    icons: [
      {
        name: 'dls-link-horizontal-light',
        path: 'assets/icons/link-horizontal-light.svg',
      },
      {
        name: 'dls-link-light',
        path: 'assets/icons/link-light.svg',
      },
      {
        name: 'dls-link-simple-light',
        path: 'assets/icons/link-simple-light.svg',
      },
      {
        name: 'dls-link-slash-light',
        path: 'assets/icons/link-slash-light.svg',
      },
    ],
  },
  {
    name: 'list',
    icons: [
      {
        name: 'dls-list-ordered-light',
        path: 'assets/icons/list-ordered-light.svg',
      },
      {
        name: 'dls-list-unordered-light',
        path: 'assets/icons/list-unordered-light.svg',
      },
    ],
  },
  {
    name: 'location-arrow',
    icons: [
      {
        name: 'dls-location-arrow-light',
        path: 'assets/icons/location-arrow-light.svg',
      },
      {
        name: 'dls-location-arrow-solid',
        path: 'assets/icons/location-arrow-solid.svg',
      },
    ],
  },
  {
    name: 'location-gps',
    icons: [
      {
        name: 'dls-location-gps-off',
        path: 'assets/icons/location-gps-off.svg',
      },
      {
        name: 'dls-location-gps-on',
        path: 'assets/icons/location-gps-on.svg',
      },
      {
        name: 'dls-location-gps-search',
        path: 'assets/icons/location-gps-search.svg',
      },
    ],
  },
  {
    name: 'map-light',
    icons: [
      {
        name: 'dls-map-light',
        path: 'assets/icons/map-light.svg',
      },
      {
        name: 'dls-map-location-dot-light',
        path: 'assets/icons/map-location-dot-light.svg',
      },
    ],
  },
  {
    name: 'map-marker',
    icons: [
      {
        name: 'dls-map-marker-check-light',
        path: 'assets/icons/map-marker-check-light.svg',
      },
      {
        name: 'dls-map-marker-dot-light',
        path: 'assets/icons/map-marker-dot-light.svg',
      },
      {
        name: 'dls-map-marker-empty-light',
        path: 'assets/icons/map-marker-empty-light.svg',
      },
      {
        name: 'dls-map-marker-minus-light',
        path: 'assets/icons/map-marker-minus-light.svg',
      },
      {
        name: 'dls-map-marker-plus-light',
        path: 'assets/icons/map-marker-plus-light.svg',
      },
      {
        name: 'dls-map-marker-x-light',
        path: 'assets/icons/map-marker-x-light.svg',
      },
    ],
  },
  {
    name: 'medications',
    icons: [
      {
        name: 'dls-medications-capsule-and-tablet-avatar',
        path: 'assets/icons/medications-capsule-and-tablet-avatar.svg',
      },
      {
        name: 'dls-medications-capsule-and-tablet-light',
        path: 'assets/icons/medications-capsule-and-tablet-light.svg',
      },
      {
        name: 'dls-medications-capsules-light',
        path: 'assets/icons/medications-capsules-light.svg',
      },
      {
        name: 'dls-medications-prescription-bottle-light',
        path: 'assets/icons/medications-prescription-bottle-light.svg',
      },
      {
        name: 'dls-medications-tablets-light',
        path: 'assets/icons/medications-tablets-light.svg',
      },
    ],
  },
  {
    name: 'microphone',
    icons: [
      {
        name: 'dls-microphone-slash-light',
        path: 'assets/icons/microphone-slash-light.svg',
      },
      {
        name: 'dls-microphone-plain-light',
        path: 'assets/icons/microphone-plain-light.svg',
      },
    ],
  },
  {
    name: 'microscope-light',
    icons: [
      {
        name: 'dls-microscope-light',
        path: 'assets/icons/microscope-light.svg',
      },
    ],
  },
  {
    name: 'minus',
    icons: [
      {
        name: 'dls-minus-circle-light',
        path: 'assets/icons/minus-circle-light.svg',
      },
      {
        name: 'dls-minus-circle-solid',
        path: 'assets/icons/minus-circle-solid.svg',
      },
      {
        name: 'dls-minus-plain-light',
        path: 'assets/icons/minus-plain-light.svg',
      },
      {
        name: 'dls-minus-square-light',
        path: 'assets/icons/minus-square-light.svg',
      },
    ],
  },
  {
    name: 'money',
    icons: [
      {
        name: 'dls-money-bill-light',
        path: 'assets/icons/money-bill-light.svg',
      },
      {
        name: 'dls-money-piggy-bank-light',
        path: 'assets/icons/money-piggy-bank-light.svg',
      },
    ],
  },
  {
    name: 'mountain',
    icons: [
      {
        name: 'dls-mountain-light',
        path: 'assets/icons/mountain-light.svg',
      },
    ],
  },
  {
    name: 'palette',
    icons: [
      {
        name: 'dls-palette-light',
        path: 'assets/icons/palette-light.svg',
      },
    ],
  },
  {
    name: 'phone-light',
    icons: [
      {
        name: 'dls-phone-light',
        path: 'assets/icons/phone-light.svg',
      },
    ],
  },
  {
    name: 'play',
    icons: [
      {
        name: 'dls-play-light',
        path: 'assets/icons/play-light.svg',
      },
      {
        name: 'dls-play-solid',
        path: 'assets/icons/play-solid.svg',
      },
    ],
  },
  {
    name: 'plus',
    icons: [
      {
        name: 'dls-plus-circle-light',
        path: 'assets/icons/plus-circle-light.svg',
      },
      {
        name: 'dls-plus-circle-solid',
        path: 'assets/icons/plus-circle-solid.svg',
      },
      {
        name: 'dls-plus-plain-light',
        path: 'assets/icons/plus-plain-light.svg',
      },
      {
        name: 'dls-plus-square-light',
        path: 'assets/icons/plus-square-light.svg',
      },
      {
        name: 'dls-plus-square-solid',
        path: 'assets/icons/plus-square-solid.svg',
      },
    ],
  },
  {
    name: 'print-light',
    icons: [
      {
        name: 'dls-print-light',
        path: 'assets/icons/print-light.svg',
      },
    ],
  },
  {
    name: 'provider',
    icons: [
      {
        name: 'dls-provider-female-1',
        path: 'assets/icons/provider-female-1.svg',
      },
      {
        name: 'dls-provider-female-2',
        path: 'assets/icons/provider-female-2.svg',
      },
      {
        name: 'dls-provider-female-3',
        path: 'assets/icons/provider-female-3.svg',
      },
      {
        name: 'dls-provider-male-1',
        path: 'assets/icons/provider-male-1.svg',
      },
      {
        name: 'dls-provider-male-2',
        path: 'assets/icons/provider-male-2.svg',
      },
      {
        name: 'dls-provider-male-3',
        path: 'assets/icons/provider-male-3.svg',
      },
      {
        name: 'dls-provider-nonbinary-1',
        path: 'assets/icons/provider-nonbinary-1.svg',
      },
      {
        name: 'dls-provider-nonbinary-2',
        path: 'assets/icons/provider-nonbinary-2.svg',
      },
      {
        name: 'dls-provider-nonbinary-3',
        path: 'assets/icons/provider-nonbinary-3.svg',
      },
    ],
  },

  {
    name: 'progress-spinner-third-light',
    icons: [
      {
        name: 'dls-progress-spinner-third-light',
        path: 'assets/icons/progress-spinner-third-light.svg',
      },
    ],
  },
  {
    name: 'question',
    icons: [
      {
        name: 'dls-question-circle-light',
        path: 'assets/icons/question-circle-light.svg',
      },
      {
        name: 'dls-question-circle-solid',
        path: 'assets/icons/question-circle-solid.svg',
      },
      {
        name: 'dls-question-plain-light',
        path: 'assets/icons/question-plain-light.svg',
      },
      {
        name: 'dls-question-square-light',
        path: 'assets/icons/question-square-light.svg',
      },
    ],
  },
  {
    name: 'search',
    icons: [
      {
        name: 'dls-search-dollar-light',
        path: 'assets/icons/search-dollar-light.svg',
      },
      {
        name: 'dls-search-plain-light',
        path: 'assets/icons/search-plain-light.svg',
      },
      {
        name: 'dls-search-no-results',
        path: 'assets/icons/search_no_results_Illustration.svg',
      },
    ],
  },
  {
    name: 'seedling',
    icons: [
      {
        name: 'dls-seedling-light',
        path: 'assets/icons/seedling-light.svg',
      },
    ],
  },
  {
    name: 'share',
    icons: [
      {
        name: 'dls-share-from-square-light',
        path: 'assets/icons/share-from-square-light.svg',
      },
    ],
  },
  {
    name: 'shield-check-light',
    icons: [
      {
        name: 'dls-shield-check-light',
        path: 'assets/icons/shield-check-light.svg',
      },
    ],
  },
  {
    name: 'shopping-cart-light',
    icons: [
      {
        name: 'dls-shopping-cart-light',
        path: 'assets/icons/shopping-cart-light.svg',
      },
    ],
  },
  {
    name: 'star',
    icons: [
      {
        name: 'dls-star-empty-one',
        path: 'assets/icons/star-empty-one.svg',
      },
      {
        name: 'dls-star-full-one',
        path: 'assets/icons/star-full-one.svg',
      },
      {
        name: 'dls-star-full-two',
        path: 'assets/icons/star-full-two.svg',
      },
      {
        name: 'dls-star-half-one',
        path: 'assets/icons/star-half-one.svg',
      },
      {
        name: 'dls-star-half-two',
        path: 'assets/icons/star-half-two.svg',
      },
    ],
  },
  {
    name: 'stethoscope-light',
    icons: [
      {
        name: 'dls-stethoscope-light',
        path: 'assets/icons/stethoscope-light.svg',
      },
    ],
  },
  {
    name: 'stopwatch-light',
    icons: [
      {
        name: 'dls-stopwatch-light',
        path: 'assets/icons/stopwatch-light.svg',
      },
    ],
  },
  {
    name: 'street-view-light',
    icons: [
      {
        name: 'dls-street-view-light',
        path: 'assets/icons/street-view-light.svg',
      },
    ],
  },
  {
    name: 'sun',
    icons: [
      {
        name: 'dls-sun-light',
        path: 'assets/icons/sun-light.svg',
      },
    ],
  },
  {
    name: 'taxi-light',
    icons: [
      {
        name: 'dls-taxi-light',
        path: 'assets/icons/taxi-light.svg',
      },
    ],
  },
  {
    name: 'thought-bubble',
    icons: [
      {
        name: 'dls-thought-bubble-light',
        path: 'assets/icons/thought-bubble-light.svg',
      },
    ],
  },
  {
    name: 'thumbs',
    icons: [
      {
        name: 'dls-thumbs-down-light',
        path: 'assets/icons/thumbs-down-light.svg',
      },
      {
        name: 'dls-thumbs-up-light',
        path: 'assets/icons/thumbs-up-light.svg',
      },
    ],
  },
  {
    name: 'times',
    icons: [
      {
        name: 'dls-times-circle-light',
        path: 'assets/icons/times-circle-light.svg',
      },
      {
        name: 'dls-times-circle-solid',
        path: 'assets/icons/times-circle-solid.svg',
      },
      {
        name: 'dls-times-plain-light',
        path: 'assets/icons/times-plain-light.svg',
      },
      {
        name: 'dls-times-square-light',
        path: 'assets/icons/times-square-light.svg',
      },
    ],
  },
  {
    name: 'tooth',
    icons: [
      {
        name: 'dls-tooth-hollow-light',
        path: 'assets/icons/tooth-hollow-light.svg',
      },
      {
        name: 'dls-tooth-solid-solid',
        path: 'assets/icons/tooth-solid-solid.svg',
      },
    ],
  },
  {
    name: 'trash-can-light',
    icons: [
      {
        name: 'dls-trash-can-light',
        path: 'assets/icons/trash-can-light.svg',
      },
    ],
  },
  {
    name: 'trophy-light',
    icons: [
      {
        name: 'dls-trophy-light',
        path: 'assets/icons/trophy-light.svg',
      },
    ],
  },
  {
    name: 'user',
    icons: [
      {
        name: 'dls-user-check-light',
        path: 'assets/icons/user-check-light.svg',
      },
      {
        name: 'dls-user-circle-light',
        path: 'assets/icons/user-circle-light.svg',
      },
      {
        name: 'dls-user-minus-light',
        path: 'assets/icons/user-minus-light.svg',
      },
      {
        name: 'dls-user-plain-light',
        path: 'assets/icons/user-plain-light.svg',
      },
      {
        name: 'dls-user-plus-light',
        path: 'assets/icons/user-plus-light.svg',
      },
      {
        name: 'dls-user-slash-light',
        path: 'assets/icons/user-slash-light.svg',
      },
      {
        name: 'dls-user-xmark-light',
        path: 'assets/icons/user-xmark-light.svg',
      },
    ],
  },
  {
    name: 'users',
    icons: [
      {
        name: 'dls-users-plain-light',
        path: 'assets/icons/users-plain-light.svg',
      },
      {
        name: 'dls-users-slash-light',
        path: 'assets/icons/users-slash-light.svg',
      },
    ],
  },
  {
    name: 'video',
    icons: [
      {
        name: 'dls-video-plain-light',
        path: 'assets/icons/video-plain-light.svg',
      },
      {
        name: 'dls-video-slash-light',
        path: 'assets/icons/video-slash-light.svg',
      },
    ],
  },
  {
    name: 'windows-open-light.svg',
    icons: [
      {
        name: 'dls-windows-open-light',
        path: 'assets/icons/windows-open-light.svg',
      },
    ],
  },
  {
    name: 'x-ray-light',
    icons: [
      {
        name: 'dls-x-ray-light',
        path: 'assets/icons/x-ray-light.svg',
      },
    ],
  },
] as const;

/**
 * At the time of writing (August 2021) there is no way in TypeScript to leveage both `as const`
 * and ensure that the more generic structure of the objects in question match an interface.
 *
 * https://github.com/microsoft/TypeScript/issues/31062
 *
 * In order to work around this we add a small amount of extra code in order to assert that the
 * svgGroupings are of the correct type of `SVGGrouping[]`.
 *
 * The reason we need the `as const` in the first place is to be able to offer a strict list of
 * icon names which exist and are available for us so that we can benefit from type checking and
 * improved compodoc generation for component inputs.
 */
type DeepReadonly<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const validateType = <T>(_obj: T) => undefined;

/**
 * If any part of the svgGroupings value is not of the correct type, the line below will show a
 * type error. See note above around TypeScript limitation for more info.
 */
validateType<DeepReadonly<SVGGrouping[]>>(svgGroupings);

// Thanks to the tricks above, this will be a union type of string literals
export type IconName = typeof svgGroupings[number]['icons'][number]['name'];

export const iconNames: IconName[] = svgGroupings.reduce<IconName[]>(
  (acc, svgGrouping) => {
    const icons: IconName[] = [];
    for (const icon of svgGrouping.icons) {
      icons.push(icon.name);
    }
    return [...acc, ...icons];
  },
  []
);
