import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SettingsService } from '../settings.service';
import { AuthService } from '../auth.service';
import { first, switchMap, withLatestFrom, tap } from 'rxjs/operators';
import { AuthStatus } from '@interfaces/auth-status.model';
import { StorageUtilities } from '../../utilities/storage.utilities';

@Injectable({
  providedIn: 'root',
})
export class NetworkToutService {
  private storage: StorageUtilities = new StorageUtilities();

  constructor(
    private settingsService: SettingsService,
    private authService: AuthService,
  ) {}

  /**
   * Determines if the tout feature is enabled based on the provided defaultNetwork status,
   * toutNetworkSelection session storage, networkCount, and user authentication status combined with the
   * tout_network_selection config settings.
   * @returns Observable<boolean>
   */
  public getToutEnabled(defaultNetwork: boolean, networkCount: number): Observable<boolean> {
    if (
      networkCount <= 1 ||
      !defaultNetwork ||
      this.storage.sessionStorageGet('toutNetworkSelection') === false
    ) {
      return of(false);
    }

    return this.settingsService.getSetting('tout_network_selection').pipe(
      withLatestFrom(
        this.authService.authStatus.pipe(first((status: AuthStatus) => !!status.resolved)),
      ),
      switchMap(([toutOn, status]) => {
        const showTout: boolean =
          (toutOn?.authenticated && toutOn?.unauthenticated) ||
          (toutOn?.authenticated && status?.auth_status) ||
          (toutOn?.unauthenticated && !status?.auth_status);
        return of(showTout);
      })
    );
  }

  public removeSessionToutNetworkSelection(): void {
    this.storage.sessionStorageRemove('toutNetworkSelection');
  }
}
