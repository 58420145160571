import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputComponent } from './search-input/search-input.component';
import { DlsIconModule } from '@zelis/dls/icons';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DlsButtonModule } from '@zelis/dls/button';
import { A11yModule } from '@angular/cdk/a11y';
import { SearchInputDirective } from './search-input/search-input.directive';
import { ResizeObserverModule } from '@ng-web-apis/resize-observer';

@NgModule({
  imports: [
    CommonModule,
    DlsIconModule,
    FormsModule,
    MatButtonModule,
    DlsButtonModule,
    A11yModule,
    ResizeObserverModule,
  ],
  declarations: [SearchInputComponent, SearchInputDirective],
  exports: [SearchInputComponent, SearchInputDirective],
})
export class DlsSearchInputModule {}
