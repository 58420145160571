import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchFilterV2 } from '@interfaces/search-filter-v2.model';

export const selectRadiusFiltersState =
  createFeatureSelector<SearchFilterV2>('radius_filters');

export const selectRadiusFilters = createSelector(
  selectRadiusFiltersState,
  (state: SearchFilterV2) => {
    return state;
  }
);

export const selectDefaultRadiusFilterRates = createSelector(
  selectRadiusFiltersState,
  (state: SearchFilterV2) => {
    if (!state['loading']) {
      return state.default.rates;
    }
    return null;
  }
);

export const selectSearchTypeDefaultRadius = (searchType: string) => createSelector(
  selectRadiusFiltersState,
  (state: SearchFilterV2) => {
    if (!state['loading']) {
      return state.default[searchType];
    }
    return null;
  }
);
