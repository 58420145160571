import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DialogService, FullScreenState } from '../dialog.service';
import { ButtonVariant } from '@zelis/dls/button';
import { coerceBoolean } from 'coerce-property';

export interface DialogButtonConfig {
  content: string;
  onClick?: (event: Event) => void;
  isHidden?: BehaviorSubject<boolean>;
  isCloseButton?: boolean;
  hasInitialFocus?: boolean;
  icon?: string;
  isDisabled?: boolean;
  variant?: ButtonVariant;
  href?: string;
  target?: string;
}

@Component({
  selector: 'zelis-dialog-footer',
  templateUrl: './dialog-footer.component.html',
})
export class DialogFooterComponent implements OnInit {
  @HostBinding('class')
  get hostClasses(): string[] {
    let classes = ['zelis-dls'];
    if (this.separator) {
      classes = [
        ...classes,
        ...[
          'border-t',
          'border-palette-m3-system-gray-85',
          '-mx-6',
          'px-4',
          'sm:px-6',
        ],
      ];
    }
    return classes;
  }

  @Input() buttons: DialogButtonConfig[] = [];
  @Input() @coerceBoolean fullScreenStackedButtons = true;
  @Input() color = 'accent';
  @Input() @coerceBoolean separator = false;

  fullScreenState$: Observable<FullScreenState> | undefined;

  constructor(
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit() {
    this.initButtons();
    this.fullScreenState$ = this.dialogService.getFullScreenStateStream(
      this.dialogRef.id
    );
  }

  private initButtons(): void {
    this.buttons?.map(
      (button) =>
        (button.isHidden = button.isHidden || new BehaviorSubject(false))
    );
  }
}
