import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NetworkSelectionWizardComponent } from '@components/network-selection-wizard/network-selection-wizard.component';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@services/settings.service';
import { StorageUtilities } from '@utilities/storage.utilities';
import { AuthStatus } from '@interfaces/auth-status.model';
import { of, first, Observable, BehaviorSubject, catchError, filter, map } from 'rxjs';
import { Place } from "@classes/place.class";
import { UuxNetwork } from "@interfaces/uux-network.model";
import { CriticalParamsService } from "@services/critical-params/critical-params.service";
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginLinks } from '@interfaces/login-links.model';
import { RouteUtilities } from '@utilities/route.utilities';

@Injectable({
  providedIn: 'root'
})
export class NetworkSelectionWizardService {
  public isOpened: boolean = false;
  public isReturnUser: boolean;
  public isBroker: boolean;
  public isDefaultLocationPage: boolean = true;
  public suppressLogin: boolean;
  public routeUtilities = new RouteUtilities();
  private networkChangeListener: BehaviorSubject<UuxNetwork> =
    new BehaviorSubject(this.storage.localStorageGet('savedNetwork'));
  private allNetworksChangeListener: BehaviorSubject<UuxNetwork[]> =
    new BehaviorSubject(this.storage.localStorageGet('allPlans'));

  constructor(
    private storage: StorageUtilities,
    private bottomSheet: MatBottomSheet,
    private translateService: TranslateService,
    private criticalParamsService: CriticalParamsService,
    private http: HttpClient,
    private settingsService: SettingsService,
  ) {}

  public getWizard(auth: AuthStatus): Observable<boolean> {
    const shouldOpenWizard = !auth.auth_status;
    const hasSetSelections = this.storage.sessionStorageGet('hasSetWizardSelections');
    this.setSuppressLoginLinks();

    if (shouldOpenWizard && !this.isOpened && !hasSetSelections) {
      this.openNetworkSelectWizard(false);
      return of(true);
    }

    return of(false);
  }

  public listenToNetworkChange(): Observable<UuxNetwork> {
    return this.networkChangeListener;
  }

  public listenToAllNetworksChange(): Observable<UuxNetwork[]> {
    return this.allNetworksChangeListener;
  }

  public saveSelectedLocation(location: Place): void {
    this.storage.localStorageSet('savedLocation', location);
  }

  public getSavedLocation(): Place {
    return this.storage.localStorageGet('savedLocation');
  }

  public saveSelectedPlan(plan: UuxNetwork): void {
    this.storage.localStorageSet('savedNetwork', plan);
    this.networkChangeListener.next(plan);
  }

  public saveAllPlans(plans: UuxNetwork[]): void {
    this.storage.localStorageSet('allPlans', plans);
    this.allNetworksChangeListener.next(plans);
  }

  public getSavedPlan(): UuxNetwork {
    return this.storage.localStorageGet('savedNetwork');
  }

  public setSelections() {
    const savedPlan = this.getSavedPlan();
    const savedLocation =
      this.getSavedLocation() ||
      this.storage.sessionStorageGet('userSelectedPlace');

    // this should no longer be needed....
    this.storage.sessionStorageSet('uuxSelectedNetwork', savedPlan);

    this.criticalParamsService.setCriticalParams({
      ci: savedPlan.ci,
      geo_location: savedLocation.geo,
    });
  }

  public setDefaultLocationPage(condition: boolean) {
    this.isDefaultLocationPage = condition;
  }

  public openNetworkSelectWizard(triggerFromDropdown: boolean): void {
    this.setUser();

    const sheetRef =
      this.bottomSheet.open(NetworkSelectionWizardComponent, {
        panelClass: 'full-screen-sheet',
        disableClose: true,
        hasBackdrop: false,
        data: { triggerFromDropdown },
        ariaModal: false,
        ariaLabel: this.translateService.instant('gated_entry_home_title')
      });
    sheetRef.afterOpened().subscribe(() => this.isOpened = true);
    sheetRef.afterDismissed().subscribe(() => this.endWizardSession());
  }

  public closeOverlay() {
    this.bottomSheet.dismiss();
  }

  public getPlans(params: any): Observable<UuxNetwork[]> {
    const url: string = `/api/marketing_plans.json`;
    const httpParams: HttpParams = new HttpParams({ fromObject: params });

    return this.http
      .get<UuxNetwork[]>(url, { withCredentials: true, params: httpParams })
      .pipe(
        catchError(() => of(null)),
        filter((response: any) => response?.marketing_plans),
        map((response: any) => response.marketing_plans.map((plan) => new UuxNetwork(plan)))
      );
  }

  private setUser(): void {
    this.checkIfBroker();
    this.checkIfReturnUser();
  }

  private setSuppressLoginLinks(): void {
    this.settingsService
      .getSetting('login_links', LoginLinks)
      .pipe(first())
      .subscribe(loginLinks => this.suppressLogin = new LoginLinks(loginLinks).suppress_login);
  }

  private checkIfReturnUser(): void {
    const savedLocation= this.getSavedLocation();
    const savedPlan = this.getSavedPlan();
    this.isReturnUser = !!savedLocation && !!savedPlan && !this.isBroker;
  }

  private checkIfBroker(): void {
    const visitorParam = this.routeUtilities.getParamFromUrl('visitor');
    this.isBroker = (visitorParam?.toLowerCase() === 'broker');
  }

  private endWizardSession(): void {
    this.storage.sessionStorageRemove('wizardPageHistory');
    this.storage.sessionStorageRemove('wizardCurrentPage');
  }
}
