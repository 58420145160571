export enum NetworkSelectionPageDescriptions {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'location-of-care' = 'Location Selection',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'plan-type-selection' = 'Plan Selection Tiles',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'product-type-selection' = 'Product Selection Tiles',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'plan-list-selection' = 'Plan List Selection',
  Employer = 'Employer State Selection',
  Medicare = 'Medicare Selection Tiles',
}
