import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { LozengeBase } from '../lozenge/lozenge.component';

export type LozengeEmphasis = 'high' | 'low';

@Component({
  selector: 'zelis-theme-lozenge',
  templateUrl: './theme-lozenge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeLozengeComponent extends LozengeBase {
  @HostBinding() class = 'zelis-dls';
  @Input() color!: string;
  @Input() emphasis!: LozengeEmphasis;
  @Input() classes?: string;

  get isHighEmphasis() {
    return this.emphasis === 'high';
  }

  get isLowEmphasis() {
    return this.emphasis === 'low';
  }

  /**
   * Because we need to dynamically construct the colors being used, we cannot leverage tailwind classes here.
   * This is because the JIT compiler in tailwind would never be able to statically discover the usage of the
   * background and text classes (because they are only known at runtime once a value for `color` has been applied),
   * and this would therefore potentially not work because the css class would have never been generated by tailwind.
   *
   * We therefore instead access the underlying css variables directly and construct the appropriate background and
   * text colors using ngStyle.
   */
  override get ngStyleExpr(): {
    [klass: string]: any;
  } | null {
    if (!this.color.includes('system') && this.isHighEmphasis) {
      return {
        'background-color': `rgb(var(--palette-${this.color}-500))`,
        color: `rgb(var(--palette-${this.color}-contrast-500))`,
      };
    }

    if (!this.color.includes('system') && this.isLowEmphasis) {
      return {
        'background-color': `rgb(var(--palette-${this.color}-50))`,
        color: `rgb(var(--palette-${this.color}-900))`,
      };
    }

    if (this.isHighEmphasis && this.color.includes('system-yellow')) {
      return {
        'background-color': `rgb(var(--palette-m3-${this.color}-85))`,
        color: `rgb(var(--palette-primary-500))`,
      };
    }

    if (this.isHighEmphasis) {
      return {
        'background-color': `rgb(var(--palette-m3-${this.color}-40))`,
        color: `rgb(var(--palette-m3-${this.color}-contrast-40))`,
      };
    }

    if (this.isLowEmphasis && this.color.includes('system-gray')) {
      return {
        'background-color': `rgb(var(--palette-m3-${this.color}-95))`,
        color: `rgb(var(--palette-m3-${this.color}-10))`,
      };
    }

    if (this.isLowEmphasis) {
      return {
        'background-color': `rgb(var(--palette-m3-${this.color}-95))`,
        color: `rgb(var(--palette-m3-${this.color}-30))`,
      };
    }
    return null;
  }
}
