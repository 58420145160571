<div class="login-prompt" data-cy="login-prompt.container">
  <div class="headline">
    <h4 class="text-medium">{{ headlineKey | translate }}</h4>
    <h4>{{ subHeadlineKey | translate }}</h4>
  </div>

  <a [href]="authUrl">
    <button
      tabindex="-1"
      class="flat-btn text-medium"
      ngClass.lt-md="mobile"
      [innerHTML]="auth.text | translate"
      (click)="productAnalyticsService.setAuthenticationPage('Pop-up CTA')"
    ></button>
  </a>

  <div>
    <p>
      <i class="no-account" aria-hidden="true">{{
        noAccountKey | translate
      }}</i>
    </p>
  </div>

  <div>
    <a [href]="auth.url" tabindex="0">{{ loginPromptKey | translate }}</a>
  </div>
</div>
