import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
} from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

export interface SnackBarOptions {
  message: string;
  metaData?: string;
  icon?: string;
  cta?: { label: string; callback(): any };
  closeBtn?: boolean;
}

@Component({
  selector: 'zelis-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarComponent {
  @HostBinding() class = 'zelis-dls';

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarOptions
  ) {}

  public cta(): void {
    this.data.cta?.callback();
    this.snackBarRef.dismiss();
  }
}
