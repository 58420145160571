import { SearchMethods } from '@enums/search-methods.enum';
import { createAction, props } from '@ngrx/store';
import { SearchParamType } from '@interfaces/search-param-type.interface';

export enum searchActionTypes {
	setSearchStateMethod = '[SET] Set Search Method',
  setFacilitySuppressWriteReview = '[SET] Set FacilitySuppressWriteReview Client Setting',
  setSearchParamType = '[SET] Set Search Param Type',
  setIsProviderTypeSubSearch = '[SET] Set is Provider Type Sub Search'
}

export const setSearchMethodAction = createAction(
  searchActionTypes.setSearchStateMethod,
  props<{ searchMethod: SearchMethods }>()
);

export const setFacilitySuppressWriteReviewAction = createAction(
  searchActionTypes.setFacilitySuppressWriteReview,
  props<{ facilitySuppressWriteReview: boolean }>()
);

export const setIsProviderTypeSubSearch = createAction(
  searchActionTypes.setIsProviderTypeSubSearch,
  props<{ isProviderTypeSubSearch: string }>()
);

export const setSearchParamType = createAction(
  searchActionTypes.setSearchParamType,
  props<{ searchParamType: SearchParamType }>()
);
