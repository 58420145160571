<span
  class="container {{ classes }} truncate"
  [ngClass]="{
    'rounded-tl-none rounded-bl-none': isAnchoredLeft,
    'rounded-tr-none rounded-br-none': isAnchoredRight,
    'large-size': isLarge,
    'medium-size': isMedium,
    'dls-icon-lozenge': icon
  }"
  [ngStyle]="ngStyleExpr"
>
  <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
  <ng-content></ng-content>
</span>
