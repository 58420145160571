import { SearchFiltersFacetQuery } from '@interfaces/search-filters-facet-query.model';
import { SearchParamType } from '@interfaces/search-param-type.interface';
import { SearchFilterV2Type } from '@interfaces/search-filter-v2-type.interface';
import { SearchFilterV2OptionDls } from './search-filter-v2-dls.model';

export class SearchFilterV2 {
  name?: string;
  description?: string;
  facet?: string;
  type?: SearchFilterV2Type;
  group?: 'main' | 'more';
  selected?: string;
  default?: any;
  hide?: string[];
  disable?: string[];
  disabled?: boolean;
  option_mapping?: Record<string, string> | null;
  option_sort?: string;
  options?: SearchFilterV2OptionDls[];
  facet_queries?: SearchFiltersFacetQuery[];
  group_tier?: boolean;
  defaultSelected?: boolean;
  subcategories?: boolean;

  constructor(obj?: SearchFilterV2, tierCode?: string) {
    this.name = obj.name || null;
    this.description = obj.description || null;
    this.facet = mapFacet(obj.facet, tierCode);
    this.type = obj.type || null;
    this.group = obj.group;
    this.default = obj.default || null;
    this.selected = obj.selected;
    this.hide = obj.hide || [];
    this.disable = obj.disable || [];
    this.disabled = obj.disabled === true;
    this.option_mapping = obj.option_mapping || null;
    this.option_sort = obj.option_sort || null;
    this.options = obj.options || [];
    this.facet_queries = mapFacetQueries(obj, this.facet);
    this.group_tier = obj.group_tier || null;
    this.defaultSelected = obj.defaultSelected || null;
    this.subcategories = obj.subcategories || null
  }
}

const mapFacet = (facet: string, tierCode: string): string => {
  if (facet && facet.indexOf('tiers') > -1) {
    facet =
      facet.split(':')[0] + ':' + (tierCode ? tierCode.toLowerCase() : '');
  }
  return facet;
};

const mapFacetQueries = (obj: any, facet: any): Array<any> => {
  if (
    facet &&
    facet.indexOf('tiers') < 0 &&
    obj.hasOwnProperty('facet_queries')
  ) {
    return obj.facet_queries;
  }
  return [new SearchFiltersFacetQuery({ facetQuery: facet, value: 'true' })];
};

export const setDefaultSelected = (
  obj: SearchFilterV2,
  searchParamType: SearchParamType
): string => {
  return obj.default && obj.default[searchParamType]
    ? obj.default[searchParamType]
    : obj.selected;
};
