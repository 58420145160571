import { Injectable } from '@angular/core';
import { SearchDisclaimersService } from '@components/+search/classes/search-disclaimers.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as SearchActions from './serp-disclaimers.actions';

@Injectable()
export class SerpDisclaimersEffects {
  getDisclaimers = createEffect(() =>
    this.actions.pipe(
      ofType(SearchActions.requestDisclaimers),
      map((action) => action.params),
      mergeMap((params) =>
        // TODO(leo): should we move things out of the search disclaimers service into the serp service?
        // There is currently a lot of business logic in existing services which will need to be evaluated and adapted.
        this.searchDisclaimersService.getDisclaimers(params).pipe(
          map((disclaimers) =>
            SearchActions.requestDisclaimersSuccess({ disclaimers })
          ),
          catchError((error) =>
            of(SearchActions.requestDisclaimersFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private readonly actions: Actions,
    private readonly searchDisclaimersService: SearchDisclaimersService
  ) {}
}
