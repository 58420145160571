import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, Observable, of, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, first, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { DynamicAssetLoaderService } from '@services/dynamic-asset-loader/dynamic-asset-loader.service';
import { WindowService } from '@services/window.service';
import { SettingsService } from '@services/settings.service';
import { NavigationEnd, Router } from '@angular/router';
import { RouteUtilities } from '@utilities/route.utilities';
import { MetadataProperty, QualtricsMetadata, SearchData } from '@interfaces/qualtricsMetadata.interface';
import { ProductAnalytics } from '@interfaces/product-analytics.interface';
import { StorageUtilities } from '@utilities/storage.utilities';
import { Store } from '@ngrx/store';
import { getResolvedNetwork } from '@store/network/network.selectors';
import { Network } from '@interfaces/network.model';

@Injectable({
  providedIn: 'root',
})
export class QualtricsService {
  private qualtricsEnabled = new BehaviorSubject<boolean>(null);
  private metadataObject = new BehaviorSubject<QualtricsMetadata>(null);
  private updateMetaSubject = new Subject<SearchData | null>();
  private routeUtilities = new RouteUtilities();
  private sessionStorage = new StorageUtilities();
  private qsiJsLoaded = new BehaviorSubject<boolean>(false);

  constructor(
    private assetLoader: DynamicAssetLoaderService,
    private windowService: WindowService,
    private settingsService: SettingsService,
    private store: Store<Network>,
    public router: Router,
  ) {}

  public setQsiJsLoaded() {
    this.qsiJsLoaded.next(!!this.windowService['QSI']?.API);
  }

  public updateMetadata(searchData: SearchData | null): void {
    this.updateMetaSubject.next(searchData)
  }

  public qualtricsListeners(): Observable<[string, QualtricsMetadata]> {
    return combineLatest([
      this.listenForPageViews(),
      this.listenForUpdatedMeta()
    ]).pipe(
      takeUntil(this.qualtricsDisabled())
    );
  }

  public initializeQualtrics(data: ProductAnalytics): Observable<QualtricsMetadata> {
   return  this.settingsService.getSetting('qualtrics_disabled').pipe(
      first(),
      switchMap(disabled => {
        // Cannot create metaObject with out data.
        // updateMetadataObject will not fire if metaObject not created
        // So no need to proceed
        if (!disabled && data) {
         return this.loadQualtrics();
        }
        return of(false);
      }),
      switchMap((loaded) => {
        this.qualtricsEnabled.next(loaded)
        if (loaded) {
          return this.createMetadataObject(data);
        }
        return of(null);
      }),
    )
  }

  private createMetadataObject(data: ProductAnalytics): Observable<QualtricsMetadata> {
    return this.waitForPendoVisitorId().pipe(
      map(visitorId => ({
        [MetadataProperty.PendoVisitorId]: visitorId,
        [MetadataProperty.Authenticated]: data.visitor.authStatus,
        [MetadataProperty.Location]: data.visitor.geolocation,
        [MetadataProperty.Gender]: data.visitor.gender,
        [MetadataProperty.PageType]: null,
        [MetadataProperty.PageUrl]: null,
        [MetadataProperty.SearchType]: null,
        [MetadataProperty.SearchMethod]: null,
        [MetadataProperty.SearchTerm]: null,
        [MetadataProperty.NetworkName]: null,
        [MetadataProperty.Client]: data.account.client,
        [MetadataProperty.AccountId]: data.account.id,
      })),
      tap(metadataObject => this.metadataObject.next(metadataObject)),
    )
  }

  private updateMetadataObject(searchData?: SearchData): Observable<QualtricsMetadata> {
    const pageName = this.routeUtilities.getState();

    if (searchData) {
      this.setSearchDataInStorage(searchData);
    }

    return this.metadataObject.pipe(
      takeUntil(this.qualtricsDisabled()),
      first(metadataObject => !!metadataObject),
      withLatestFrom(this.store.select(getResolvedNetwork)),
      switchMap(([metadataObject, network]) => {
        if (!searchData) {
          searchData = this.getSearchDataFromStorage();
        }
        return of({
         ...metadataObject,
          [MetadataProperty.PageType]: pageName.replace(/\-/g, ' '),
          [MetadataProperty.PageUrl]: this.getPath(this.router.url),
          [MetadataProperty.SearchType]: searchData?.searchName || null,
          [MetadataProperty.SearchMethod]: searchData?.searchMethod || null,
          [MetadataProperty.SearchTerm]: searchData?.searchTerm || null,
          [MetadataProperty.NetworkName]: network.name || null,
        });
      }),
      switchMap(metadataObject => {
        return this.apiLoaded().pipe(
          map(() => metadataObject),
          tap(() => {
            this.metadataObject.next(metadataObject)
            this.pushQualtricsDataToWindow(metadataObject)
          })
        )
      })
    );
  }

  private apiLoaded(): Observable<boolean>{
    return this.qsiJsLoaded.pipe(
      filter(value => value),
      take(1)
    )
  }

  private qualtricsDisabled(): Observable<boolean> {
    return this.qualtricsEnabled.pipe(
      filter(loaded => loaded === false),
      take(1)
    )
  }

  private pushQualtricsDataToWindow(metadataObject: QualtricsMetadata): void {
    const qsiApi = this.windowService['QSI'].API;
    qsiApi.unload();
    this.windowService['qualtrics'] = metadataObject;
    qsiApi.load().then(qsiApi.run(), () => console.warn('error qsiApi.load'));
  }

  private getPath(url: string): string {
    const parsedUrl = this.routeUtilities.parseUrl(url);
    return '/' + parsedUrl.pathObject.segments.slice(0, 3).join('/').toLowerCase();
  }

  private waitForPendoVisitorId(): Observable<string> {
    return interval(50).pipe(
      map(() => this.windowService['pendo'].visitorId),
      first(visitorId => visitorId !== undefined),
    );
  }

  private loadQualtrics(): Observable<boolean> {
    const id = 'qualtrics_id'
    const scriptElem = document.getElementById(id);
    scriptElem?.remove();
    return this.assetLoader.loadAsset(
      '../../../assets/scripts/qualtrics.js',
      'script',
      id
    );
  }

  private setSearchDataInStorage(searchData: SearchData): void {
   this.sessionStorage.sessionStorageSet('searchData', searchData);
 }

  private getSearchDataFromStorage(): SearchData {
   return this.sessionStorage.sessionStorageGet('searchData');
 }

 private listenForUpdatedMeta(): Observable<QualtricsMetadata> {
    return this.updateMetaSubject.pipe(
      concatMap((searchData?: SearchData) => this.updateMetadataObject(searchData))
    );
  }

  private listenForPageViews(): Observable<string> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.routeUtilities.getState()),
      distinctUntilChanged(),
      tap(() => this.updateMetadata(null)),
    );
  }
}
