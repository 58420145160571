import { map, catchError, switchMap, take } from 'rxjs/operators';
import { Observable, forkJoin, of, combineLatest, BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { SearchParamsService } from '../search-params.service';
import { SearchFiltersService } from '@components/+search/classes/search-filters.service';
import { SearchFilter } from '@interfaces/search-filter.model';
import { CriticalFilters } from '@interfaces/critical-filters.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NetworksService } from '@services/networks.service';
import { SearchFiltersSettings } from './search-filters-settings.class';
import { MembersService } from '@services/members.service';
import { extend, each, find } from 'lodash';
import { SearchFacetParamsService } from '@components/+search/classes/search-facet-params.service';
import { SortConfig } from '@interfaces/sort-config.model';
import { RadiusFilter } from '@interfaces/radius-filter.interface';
import { AppParams } from '@interfaces/app.interface.appParams';
import { FiltersUtilities } from '@utilities/filters.utilities';
import { SearchSortOption } from '@interfaces/search-sort-option.model';
import { SearchParamType } from '@interfaces/search-param-type.interface';
import { SearchFiltersFacetQuery } from '@interfaces/search-filters-facet-query.model';
import { SearchFilterOption } from '@interfaces/search-filter-option.model';
import { SearchFiltersV2Actions } from '@store/search-filters-v2';
import { Store } from '@ngrx/store';
import { SearchFilterV2 } from '@interfaces/search-filter-v2.model';
import { SerpService } from '@services/serp/serp.service';

@Injectable({
  providedIn: 'root',
})
export class SearchFilters {
  public areFiltersSelected: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public reset: boolean = false;
  public facetsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public configuredDropdown: Observable<SearchFilter[]> =
    this.searchFiltersService.dropdownItems;
  public configuredNestedDropdown: Observable<SearchFilter[]> =
    this.searchFiltersService.nestedDropdownItems;
  public configuredCheckbox: Observable<SearchFilter[]> =
    this.getConfiguredCheckbox();

  private incentivizedFilters: string[] = ['has_incentive'];
  private radiusExpanded: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private searchParamsService: SearchParamsService,
    private searchFiltersService: SearchFiltersService,
    private searchFiltersSettings: SearchFiltersSettings,
    private http: HttpClient,
    private networksService: NetworksService,
    private membersService: MembersService,
    private searchFacetParamsService: SearchFacetParamsService,
    private filtersUtilities: FiltersUtilities,
    private store: Store,
    private serpService: SerpService
  ) {}

  public getSort(): SortConfig {
    return this.searchFiltersSettings.search_sort;
  }

  public getRadius(): SearchFilter {
    return this.searchFiltersSettings.search_radius;
  }

  public getConfiguredCheckbox(): Observable<SearchFilter[]> {
    return combineLatest([
      this.membersService.incentivesEnabled,
      this.searchFiltersService.checkboxItems,
    ]).pipe(
      map(([incentivesEnabled, checkboxItems]) =>
        this.filterOnIncentive(incentivesEnabled, checkboxItems)
      )
    );
  }

  // TODO V2 Filters: get sort filters from store
  public getFiltersWithSort(sort: SearchSortOption, params: AppParams): any {
    if (this.hasPerformanceScore(sort.query, params)) {
      sort.query = this.addSpecialtyIdToQuery(sort.query, params);
    }
    const sortFilter = {
      sort: sort.query,
      sort_translation: sort.translation,
    };
    return this.encodeFilters(sortFilter);
  }

  // TODO V2 Filters: get radius from store
  public getFiltersWithRadius(radiusFilter: RadiusFilter): any {
    this.getRadius().selected = radiusFilter.radius;
    return this.encodeFilters(radiusFilter);
  }

  public getExpandedRadiusFilters(radius: string): any {
    this.radiusExpanded.next(radius);
    return this.getFiltersWithRadius({ radius: radius });
  }

  public extendFilters(selectedFilter: any): any {
    selectedFilter = this.encodeFilters(selectedFilter);
    this.store.dispatch(SearchFiltersV2Actions.setSelectedFilters({ selectedFilters: selectedFilter }));
  }

  public getGroupTierValue(
    networkTierCode: string,
    filterConfig: SearchFilter[]
  ): boolean {
    if (!networkTierCode || !filterConfig) {
      return false;
    }
    const hasTierCode = `tiers:${networkTierCode.toLowerCase()}`;
    const foundTiersFacet = filterConfig.filter((obj: SearchFilter) => {
      return obj.facet === hasTierCode;
    });
    return foundTiersFacet.length > 0 ? foundTiersFacet[0].group_tier : false;
  }

  // This logic is similar to getFacets() but returns search filters that were augmented with facet data
  // These search filters then replace the existing store search filters
  // getFacets() returns the facets response and mutates search filters in other services
  public getFiltersWithFacetData(
    searchParamType: SearchParamType,
    combinedParams: any,
    filtersFromStore: SearchFilter[] = null
  ): Observable<any> {
    combinedParams = this.cleanSpecialtySearchFilters(
      searchParamType,
      combinedParams
    );
    return this.getFilteredConfigByType(
      searchParamType,
      combinedParams,
      filtersFromStore
    ).pipe(
      switchMap((filteredByType: SearchFilter[]) => {
        return combineLatest([
          this.getCombinedFacetRequests(
            combinedParams,
            searchParamType,
            filteredByType
          ),
          this.networksService.resolvedNetwork,
        ]).pipe(
          switchMap(([facets, network]) => {
            const filterWithFacetData =
              this.searchFiltersService.matchFacetOptionsForStoreData(
                facets,
                filteredByType,
                network.tier_code
              );
            return of(filterWithFacetData);
          })
        );
      })
    );
  }

  public getFacets(
    searchParamType: SearchParamType,
    combinedParams: any
  ): Observable<any> {
    combinedParams = this.cleanSpecialtySearchFilters(
      searchParamType,
      combinedParams
    );
    return this.getFilteredConfigByType(searchParamType, combinedParams).pipe(
      switchMap((filteredByType: any) => {
        return combineLatest([
          this.getCombinedFacetRequests(
            combinedParams,
            searchParamType,
            filteredByType
          ),
          this.networksService.resolvedNetwork,
        ]).pipe(
          switchMap(([facets, network]) => {
            this.searchFiltersService.matchFacetOptions(
              facets,
              filteredByType,
              network.tier_code
            );
            this.searchFiltersService.filterMatchedFacets();
            this.facetsLoading.next(false);
            return of(facets);
          })
        );
      })
    );
  }

  public applyURIFilters(
    searchFilters: SearchFilter[],
    searchParamType: SearchParamType,
    selectedFilters
  ): void {
    if (selectedFilters.sort) {
      const matchedSort = this.filtersUtilities.getMatchedSort(
        searchParamType,
        selectedFilters.sort,
        this.getSort()
      );
      // TODO V2 - this should be moved to the store
      this.getSort().selected = matchedSort;
    }
    this.areFiltersSelected.next(this._areFiltersSelected(searchFilters));
  }

  public findSortTranslation(): string {
    return (
      this.getSort().default.find(
        (option) => option.query === this.getSort().selected.query
      ) || {}
    ).translation;
  }

  public buildFacetLocationGeo(radiusOptions: SearchFilterOption[]) {
    const facet = 'location_geo';
    return new SearchFilterV2({
      facet: facet,
      facet_queries: [
        new SearchFiltersFacetQuery({
          facetQuery: facet,
          value: this.radiusOptionsToString(radiusOptions),
        }),
      ],
    });
  }

  private radiusOptionsToString(radiusOptions: SearchFilterOption[]): string {
    const radiusOptionsString: string[] = [];
    radiusOptions.forEach((option) => radiusOptionsString.push(option.value));
    return radiusOptionsString.join(',');
  }

  private cleanSpecialtySearchFilters(
    searchParamType: SearchParamType,
    filtersWithParams: any
  ): any {
    if (searchParamType === 'serp_lite') {
      delete filtersWithParams.field_specialty_ids;
      delete filtersWithParams.expertise_codes;
    }
    return filtersWithParams;
  }

  // Get facets individually in parallel and combine into a single object.
  // API has better performance when requested individually.
  private getCombinedFacetRequests(
    selectedFilters: CriticalFilters,
    searchParamType: SearchParamType,
    filteredByType: any
  ): Observable<any> {
    const params = this.searchParamsService.setHttpParams(
      this.decodeFilter(selectedFilters),
      null,
      true
    );
    return forkJoin(
      this.getFacetRequests(params, searchParamType, filteredByType)
    ).pipe(map((facetResults: any[]) => extend({}, ...facetResults)));
  }

  private getFacetRequests(
    params: HttpParams,
    searchParamType: SearchParamType,
    searchFilters: SearchFilterV2[]
  ): Observable<any> {
    let facetRequestParams =
      this.searchFacetParamsService.getFacetRequestParams(
        params,
        searchFilters
      );
    if (searchParamType === 'serp_lite') {
      facetRequestParams.keys().forEach((key: string) => {
        if (this.isFacetParam(key) && !this.isSerpLiteFacet(key)) {
          facetRequestParams = facetRequestParams.delete(key);
        }
      });
    }
    if (searchParamType === 'rates') {
      let serpSummaryParams = this.serpService.httpParams;
      facetRequestParams.keys().forEach((key: string) => {
        if (this.isFacetParam(key)) {
          serpSummaryParams = serpSummaryParams.set(key, facetRequestParams.get(key));
        }
      })
      facetRequestParams = serpSummaryParams;
    }
    return this.requestFacet(facetRequestParams);
  }

  private isSerpLiteFacet(key: string): boolean {
    const serpLiteFacets = ['facet[expertise_codes]', 'facet[field_specialty_ids]'];
    return serpLiteFacets.includes(key);
  }

  private isFacetParam(key: string): boolean {
    return key.includes('facet');
  }

  // Get filtered search filters by current search type
  // Some filters need to be hidden on a particular search type, i.e. hide has_incentive on search_specialty searches
  private getFilteredConfigByType(
    searchParamType: SearchParamType,
    filtersWithParams: any,
    filtersFromStore: SearchFilter[] = null
  ): Observable<SearchFilter[]> {
    if (filtersFromStore === null) {
      this.facetsLoading.next(true);
      this.searchFiltersService.resetMatchedFacets();
    }

    return this.radiusExpanded.pipe(
      take(1),
      switchMap((radius: string) => {
        if (radius) {
          filtersWithParams = { ...filtersWithParams, radius: radius };
        }
        return this.searchFiltersSettings.checkConfigToHideOrDisable(
          searchParamType,
          filtersWithParams,
          filtersFromStore
        );
      })
    );
  }

  private requestFacet(params: HttpParams): Observable<any> {
    params = this.searchFacetParamsService.updateTiersFacetableParam(params);
    const url = `/api/providers/facets.json`;
    return this.http.get(url, { params: params, withCredentials: true }).pipe(
      catchError(() => of(null)),
      map((data: any) => (data && data.facets) || null),
      map((data: any) => this.mapFacets(data))
    );
  }

  private setFilterSelectedValue(filterObj: SearchFilter, selectedFilters): void {
    filterObj.selected = undefined;
    if (selectedFilters && selectedFilters[filterObj.facet]) {
      filterObj.selected = selectedFilters[filterObj.facet];
      filterObj.defaultSelected = true;
    }
  }

  private _areFiltersSelected(searchFilters: SearchFilter[]): boolean {
    return !!find(searchFilters, (filterObj) => {
      if (filterObj.selected) {
        return true;
      }
      return find(filterObj.items, (item) => item.selected);
    });
  }

  private filterOnIncentive(
    incentivesEnabled: boolean,
    filters: SearchFilter[]
  ): SearchFilter[] {
    return filters.filter((item) => {
      if (
        !incentivesEnabled &&
        this.incentivizedFilters.indexOf(item.facet) > -1
      ) {
        return false;
      }
      return item;
    });
  }

  private decodeFilter(
    filters: CriticalFilters,
    filterString = 'provider_type_description'
  ): CriticalFilters {
    if (filters[filterString]) {
      filters[filterString] = decodeURIComponent(filters[filterString]);
    }
    return filters;
  }

  private encodeFilters(filters: any): any {
    const invalidURI = /[ :/?#\[\]@!$&'\(\)*+;=%]+/;
    if (filters) {
      Object.keys(filters).forEach((filterKey) => {
        if (filterKey !== 'sort' && filterKey !== 'sort_translation') {
          if (invalidURI.test(filters[filterKey])) {
            // decoding filter keys before encoding to avoid double encoding
            filters[filterKey] = encodeURIComponent(decodeURIComponent(filters[filterKey]));
          }
        }
      });
    }
    return filters;
  }

  private mapFacets(facets: any): any {
    return this.searchFacetParamsService.updateTiersFacetableProperty(facets);
  }

  private addSpecialtyIdToQuery(query: string, params?: any): string {
    return query
      .split(' ')
      .map((piece) => {
        if (
          piece.indexOf('performance_score:') > -1 &&
          params.search_specialty_id
        ) {
          piece = piece.split(':')[0] + ':s' + params.search_specialty_id;
        }
        return piece;
      })
      .join(' ');
  }

  private hasPerformanceScore(query: string, params: AppParams): boolean {
    return !!(
      query &&
      query.includes('performance_score:') &&
      params &&
      params.search_specialty_id
    );
  }
}
