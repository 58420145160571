import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  first,
  takeUntil,
  distinctUntilChanged,
  withLatestFrom,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RouteUtilities } from '@utilities/route.utilities';
import { SearchFiltersSettings } from '@components/+search/classes/search-filters-settings.class';
import { setDefaultSelected } from '@interfaces/search-filter.model';
import { TranslateService } from '@ngx-translate/core';
import { AutocompleteResult } from '@interfaces/autocomplete-result.model';
import { AutocompleteResults } from '@interfaces/autocomplete-results.model';
import { AutocompleteResultsCount } from '@interfaces/autocomplete-results-count.model';
import { AutocompleteResultsCounter } from '@interfaces/autocomplete-results-counter.model';
import { Router } from '@angular/router';
import {
  Subject,
  Observable,
  BehaviorSubject,
  of,
  combineLatest,
  forkJoin,
} from 'rxjs';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { HttpParams, HttpClient } from '@angular/common/http';
import { AppParamsService } from '@services/app.params.service';
import { SearchParams } from '@interfaces/search-params.model';
import { ProceduresService } from '@services/procedures.service';
import { FeaturesService } from '@services/features/features.service';
import { SearchNavigationParams } from '@interfaces/search-navigation-params.interface';
import { GoogleTagManagerService } from '@services/analytics/google-tag-manager.service';
import { extend } from 'lodash';
import { IncentivizedProcedure } from '@interfaces/incentivized-procedure.model';
import { CriticalParamsService } from '@services/critical-params/critical-params.service';
import { AutocompleteParams } from '@interfaces/autocomplete-params.model';
import { SearchRouteType } from '@interfaces/search-route-type.interface';
import { SearchParamType } from '@interfaces/search-param-type.interface';
import { StringParseUtilities } from '@utilities/string-parse.utilities';
import { FeatureFlagSet } from '@interfaces/feature-flag-set.interface';
import { SearchTrigger } from '@classes/search-trigger.class';
import { MembersService } from '@services/members.service';
import { CouponsService } from '@services/coupons.service';
import { MedicationFinderService } from '@services/medication-finder.service';
import { CouponsDrugSearchResult } from '@interfaces/coupons-drug-search-result.interface';
import searchTypeMap from '@utilities/search-type-map.utilities';

@Injectable({
  providedIn: 'root',
})
export class TermAutosuggest {
  public subscriptions = new SubscriptionManager();
  public autocompleteResults = new AutocompleteResults();
  public autocompleteResultsAltTrigger: BehaviorSubject<AutocompleteResults> =
    new BehaviorSubject(this.autocompleteResults);
  public autocompleteResultsChange: BehaviorSubject<AutocompleteResults> =
    new BehaviorSubject(this.autocompleteResults);
  public autocompleteResultsCounter = new AutocompleteResultsCount();
  public selectedAutocomplete: any;
  public selectedTerm: string = '';
  public selectedGroup: string = '';
  public autosuggestChosen = new Subject<boolean>();

  private routeUtilities = new RouteUtilities();
  private setDefaultFilter = setDefaultSelected;
  private cancelRequest: Subject<void> = new Subject();

  constructor(
    public criticalParamsService: CriticalParamsService,
    public router: Router,
    public searchFiltersSettings: SearchFiltersSettings,
    private translateService: TranslateService,
    private appParamsService: AppParamsService,
    private http: HttpClient,
    private proceduresService: ProceduresService,
    private googleTagManagerService: GoogleTagManagerService,
    private stringParseUtilities: StringParseUtilities,
    private featuresService: FeaturesService,
    private searchTrigger: SearchTrigger,
    private membersService: MembersService,
    private couponsService: CouponsService,
    private medicationFinderService: MedicationFinderService
  ) {}

  public activateHome(): void {
    this.clear();
    let criticalParams =
      this.criticalParamsService.getParamsFromUrlAndCritical();
    if (criticalParams.page) {
      criticalParams.page = null;
    }

    if (criticalParams.radius) {
      criticalParams.radius = null;
    }

    criticalParams.billing_code = null;
    criticalParams = this.removeCouponsParams(criticalParams);

    this.router.navigate(['/'], { queryParams: criticalParams });
  }

  public activateSearch(params: SearchNavigationParams, filters?: any): void {
    if (params.type === 'incentivized_procedures') {
      params.type = 'procedures';
    }
    if (params.id && params.type === 'procedures') {
      this.proceduresService
        .requestIncentivizedProcedure(String(params.id))
        .pipe(
          catchError(() => of(undefined)),
          first()
        )
        .subscribe((incentivizedProcedure: IncentivizedProcedure) => {
          this.navigateToSearch(params, filters, incentivizedProcedure);
        });
    } else {
      this.navigateToSearch(params, filters);
    }
  }

  public clear(): void {
    this.selectedTerm = '';
    this.autocompleteResults = new AutocompleteResults();
    this.searchTrigger.autosuggest.next(null);
  }

  public getAutocompleteResults(
    termEntered: string
  ): Observable<AutocompleteResults> {
    this.selectedTerm = termEntered;
    this.selectGroup('');
    this.cancelPendingRequests();

    return combineLatest([
      this.criticalParamsService.criticalParamsSubject.pipe(
        distinctUntilChanged()
      ),
      this.proceduresService.proceduresUpdated.pipe(
        map((procedures) => procedures && procedures.length > 0),
        distinctUntilChanged()
      ),
      this.membersService.member.pipe(
        map(member => member?.rx_savings_eligible),
        distinctUntilChanged()
      ),
    ]).pipe(
      withLatestFrom(this.couponsService.isRxCouponsEligible),
      switchMap(([[_criticalParams, procedures, rxSavingsEligible], rxCouponsEligible]) => {
        return of(termEntered).pipe(
          filter((term) => !!term),
          switchMap((term: string) => {
            const requests = [];
            this.setSearchTypes(procedures, rxSavingsEligible, rxCouponsEligible).forEach(
              (searchType: SearchRouteType) => {
                requests.push(this.requestAutocomplete(searchType, term));
              }
            );
            return forkJoin(requests);
          }),
          map((results) => this.mapToAutocompleteResults(results)),
          tap((results) => this.clearProcedureResults(results, procedures))
        );
      })
    );
  }

  public onAutocompleteSelect(
    selectedResult: AutocompleteResult,
    carepathEnabled: boolean
  ): void {
    this.selectedTerm = selectedResult.name;
    this.selectedAutocomplete = selectedResult || {};
    this.autosuggestChosen.next(true);

    const { type } = selectedResult;
    if(type === 'rx_coupons' || type === 'rx_savings') {
      return this.navigateToRx(selectedResult);
    }

    if (carepathEnabled && this.selectedAutocomplete.episode_procedure) {
      this.goToCarepath(this.selectedAutocomplete);
    } else if (
      this.selectedAutocomplete &&
      this.selectedAutocomplete.provider_id
    ) {
      this.activateProfile(this.selectedAutocomplete);
    } else if (this.selectedAutocomplete) {
      this.activateSearch(this.selectedAutocomplete, {});
    }
  }

  public requestAutocomplete(
    searchType: SearchRouteType,
    name: string
  ): Observable<any> {

    if(searchType === 'rx_savings' || searchType === 'rx_coupons') {
      return this.requestRxAutoComplete(searchType, name);
    }
    const url = `/api/${searchType}/autocomplete.json`;
    return this.featuresService.getFeatureFlags().pipe(
      switchMap((features: FeatureFlagSet) => {
        const params = this.setAutocompleteParams(name, searchType, features);
        return this.http
          .get(url, { params: params, withCredentials: true })
          .pipe(
            catchError(() => of({ [searchType]: [] })),
            takeUntil(this.cancelRequest),
            filter((results) => !!results),
            map((results: any) => {
              const mapped: any = {
                [searchType]: this.mapEachAutocompleteResult(
                  results,
                  searchType
                ),
              };
              mapped.telehealth = results.telehealth;
              return mapped;
            })
          );
      })
    );
  }

  public searchRtsProcedure(procedureId: number): void {
    const criticalParams =
      this.criticalParamsService.getParamsFromUrlAndCritical();
    this.router.navigate(['search/rts/', procedureId, 1], {
      queryParams: criticalParams,
    });
  }

  public selectGroup(group: string): void {
    this.selectedGroup = group;
  }

  public setAutocompleteParams(
    name: string,
    searchType: SearchRouteType,
    features: FeatureFlagSet
  ): HttpParams {
    let _params = {};
    if (
      features?.location_sensitive_search_specialty_autosuggest &&
      searchType === 'search_specialties'
    ) {
      _params = { limit: '-1' };
    }
    const autocompleteParams = new AutocompleteParams(_params);

    const allParams = this.criticalParamsService.getParamsFromUrlAndCritical();

    let params: HttpParams = this.appParamsService.setHttpParams(
      new SearchParams(allParams)
    );

    if (searchType === 'procedures') {
      const procedure_params = this.proceduresService.setProcedureParams(null);
      procedure_params.keys().forEach((k) => {
        params = params.set(k, procedure_params.get(k));
      });
    }

    if (name) {
      params = params.set('name', name);
    }

    params = params.delete('field_specialty_ids');
    params = params.delete('expertise_codes');
    params = params.set('page', '1');
    params = params.set(
      'radius',
      this.getAutosuggestRadiusDefault(autocompleteParams, searchType)
    );
    params = params.set('sort', autocompleteParams.sort);
    params = params.set('limit', autocompleteParams.limit);

    return params;
  }

  public goToCarepath(result: AutocompleteResult): void {
    this.router.navigate(['carepath', result.id, 'preview'], {
      queryParamsHandling: 'preserve',
    });
  }

  private requestRxAutoComplete(
    searchType: SearchRouteType,
    name: string
  ): Observable<{ [x: string]: AutocompleteResult[] }> {
    const typeCoupons = searchType === 'rx_coupons';
    const rxAutoCompleteService: Observable<
      CouponsDrugSearchResult[] | string[]
    > = typeCoupons
      ? this.couponsService.getDrugAutoCompleteOptions(name)
      : this.medicationFinderService.getDrugNames(name);

    return rxAutoCompleteService.pipe(
      map((drugs: any[]) => {
        drugs = drugs || [];
        return drugs.map((d) => {
            return {
              name: typeCoupons ? d.seo_name : d,
              id: typeCoupons ? d.ndc : null,
            };
          });
        }
      ),
      takeUntil(this.cancelRequest),
      map((results) => ({
        [searchType]: this.mapEachAutocompleteResult(
          { [searchType]: results },
          searchType
        ),
      }))
    );
  }

  private navigateToRx({ type, name, id }: AutocompleteResult): void {
    const route = type === 'rx_coupons' ? '/coupons' : '/medication-finder';

    this.router.navigate([route, name], {
      queryParams: { ndc: id },
      queryParamsHandling: 'merge',
    });
  }

  private cancelPendingRequests(): void {
    this.cancelRequest.next();
  }

  private activateProfile(params: any): void {
    this.pushGtmData(params);
    this.router.navigate(
      [
        'profile',
        params.provider_id,
        params.locations[0].id,
        JSON.stringify({
          name: params.name,
          client_canonical_id: params.client_canonical_id,
          state: params.locations[0].state,
          npi_identifier: params.npi_identifier,
        }),
      ],
      {
        queryParams: { out_of_network: params.out_of_network },
        queryParamsHandling: 'merge',
      }
    );
  }

  private clearProcedureResults(
    results,
    procedures: boolean
  ): AutocompleteResults {
    if (!procedures) {
      results['procedures'] = [];
    }
    return results;
  }

  private getAutosuggestRadiusDefault(
    autocompleteParams: AutocompleteParams,
    searchType: SearchRouteType
  ): string {
    const searchParamType = this.routeUtilities.getSearchParamType(searchType);
    const radiusSetting =
      this.searchFiltersSettings &&
      this.searchFiltersSettings.search_radius &&
      this.searchFiltersSettings.search_radius.default &&
      this.searchFiltersSettings.search_radius.default[searchParamType];

    return radiusSetting || autocompleteParams.radius;
  }

  private getFilterDefaults(
    searchType: any,
    incentedProcedure: IncentivizedProcedure
  ): string {
    const searchParamType = this.routeUtilities.getSearchParamType(searchType);
    const mappedSearchType: SearchParamType = searchTypeMap[searchParamType] || searchParamType;

    const sortByType =
    this.searchFiltersSettings.getSortDefaultByType(mappedSearchType);
    let defaults: any = {
      limit: 10,
      radius: this.getRadiusDefault(mappedSearchType, incentedProcedure),
      sort: sortByType.query,
      sort_translation: sortByType.translation,
    };

    defaults = Object.assign(
      {},
      defaults,
      this.searchFiltersSettings.getFilterDefaultsAsFacets(mappedSearchType)
    );
    return JSON.stringify(defaults);
  }

  // TODO V2 Refactor to grab from the store upstream
  private getRadiusDefault(
    searchParamType: SearchParamType,
    incentedProcedure: IncentivizedProcedure
  ): string {
    let out = this.setDefaultFilter(
      this.searchFiltersSettings.search_radius,
      searchParamType
    );

    if (incentedProcedure?.minimum_search_radius) {
      // Do not override with min search radius if it's smaller than the default
      if (incentedProcedure.minimum_search_radius > Number(out)) {
        out = String(incentedProcedure.minimum_search_radius);
      }
    }
    return out;
  }

  private mapEachAutocompleteResult(
    results: any,
    searchType: SearchRouteType
  ): AutocompleteResult[] {
    const mappedResults = { ...results };
    if (results[searchType]) {
      mappedResults[searchType] = mappedResults[searchType].map(
        (result) =>
          new AutocompleteResult(
            result,
            this.translateService.currentLang,
            searchType
          )
      );
      if (searchType === 'procedures') {
        this.autocompleteResultsAltTrigger.next(mappedResults);
      }
      this.autocompleteResultsCounter[searchType] =
        new AutocompleteResultsCounter({
          remaining: mappedResults[searchType].length,
        });
    }
    return mappedResults[searchType];
  }

  private mapToAutocompleteResults(results: any[]): AutocompleteResults {
    const combinedResults = results.reduce(
      (mappedResults, resultsBySearchType) => {
        const searchType = Object.keys(resultsBySearchType)[0];
        return {
          ...mappedResults,
          [searchType]: resultsBySearchType[searchType],
        };
      },
      {}
    );
    // Telehealth is duplicated in each result set
    combinedResults.telehealth = results[0].telehealth;
    this.autocompleteResultsChange.next(combinedResults);
    return combinedResults;
  }

  private navigateToSearch(
    params: SearchNavigationParams,
    filters: any,
    incentedProcedure?: IncentivizedProcedure
  ): void {
    const searchRouteType = params.type;
    const routeParam =
      params.id ||
      params.name ||
      params.hospital_affiliation_ids ||
      params.group_affiliation_ids;
    const extraParams: any = params.extra || {};
    const queryParams = extend(
      {},
      this.criticalParamsService.getParamsFromUrlAndCritical(),
      extraParams
    );
    const filterDefaults = this.stringParseUtilities.jsonParse(
      this.getFilterDefaults(searchRouteType, incentedProcedure)
    );
    const mergedFilters = { ...filterDefaults, ...filters };

    if (queryParams.page) {
      queryParams.page = null;
    }

    if (queryParams.radius) {
      queryParams.radius = null;
    }

    if (queryParams.billing_code) {
      queryParams.billing_code = null;
    }

    this.searchFiltersSettings.setIncentivizedRadius(
      incentedProcedure?.minimum_search_radius
    );

    this.router.navigate(
      [
        `search/${searchRouteType}/`,
        routeParam,
        1,
        JSON.stringify(mergedFilters),
      ],
      {
        queryParams: queryParams,
      }
    );
  }

  private pushGtmData(params: any): void {
    const type =
      params.provider_id.charAt(0) === 'p' ? 'Professional' : 'Facility';
    this.googleTagManagerService.pushToDataLayer({
      event: 'gtm.selected_autosuggest_query',
      selected_autosuggest_query_type: type + ' Autosuggest Query',
      selected_autosuggest_query_text: params.name,
    });
  }

  private setSearchTypes(
    procedures: boolean,
    rxSavingsEligible: boolean,
    rxCouponsEligible: boolean,
  ): SearchRouteType[] {
    let searchTypes: SearchRouteType[] = procedures
      ? ['providers', 'search_specialties', 'procedures']
      : ['providers', 'search_specialties'];

    if(rxSavingsEligible || rxCouponsEligible) {
      searchTypes = [
        ...searchTypes,
        rxSavingsEligible ? 'rx_savings': 'rx_coupons'
      ]
    }

    return searchTypes;
  }
  private removeCouponsParams(criticalParams: any): any {
    criticalParams.ndc = null;
    criticalParams.form = null;
    criticalParams.dosage = null;
    criticalParams.quantity = null;
    return criticalParams;
  }
}
