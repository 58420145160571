import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  Input,
  Directive,
} from '@angular/core';

@Directive()
export abstract class LozengeBase {
  @Input() size!: 'large' | 'medium';
  @Input() anchor?: 'left' | 'right';
  @Input() type?: 'theme';
  @Input() icon?: string;

  get isLarge() {
    return this.size === 'large';
  }

  get isMedium() {
    return this.size === 'medium';
  }

  get isAnchoredLeft() {
    return this.anchor === 'left';
  }

  get isAnchoredRight() {
    return this.anchor === 'right';
  }

  protected _ngStyleExpr: { [klass: string]: any } | null = null;
  get ngStyleExpr(): {
    [klass: string]: any;
  } | null {
    return this._ngStyleExpr;
  }
  set ngStyleExpr(val: { [klass: string]: any } | null) {
    this._ngStyleExpr = val;
  }
}

@Component({
  selector: 'zelis-lozenge',
  templateUrl: './lozenge.component.html',
  styleUrls: ['./lozenge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LozengeComponent extends LozengeBase {
  @HostBinding()
  class = 'zelis-dls';

  @Input() classes?: string;

  @Input() override get ngStyleExpr(): {
    [klass: string]: any;
  } | null {
    return this._ngStyleExpr;
  }
  override set ngStyleExpr(val: { [klass: string]: any } | null) {
    this._ngStyleExpr = val;
  }
}
