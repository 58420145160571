import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchFiltersV2State } from '@store/search-filters-v2/search-filters-v2.state';

export const selectFiltersState =
  createFeatureSelector<SearchFiltersV2State>('search_filters_v2');

export const selectSearchFilters = createSelector(
  selectFiltersState,
  (state: SearchFiltersV2State) => {
    return state;
  }
);

export const getSelectedFilters = createSelector(
  selectSearchFilters,
  (state) => state.selectedFilters
);

export const getAppliedFilters = createSelector(
  selectSearchFilters,
  (state) => {
    if (!state.appliedFilters?.loading) {
      return state.appliedFilters?.filters;
    }
  }
);

export const getBaseFilters = createSelector(
  selectSearchFilters,
  (state) => {
    if (!state.baseFilters?.loading) {
      return state.baseFilters?.filters;
    }
  }
);

export const getConfigFilters = createSelector(
  selectSearchFilters,
  (state) => {
    if (!state.configFilters?.loading) {
      return state.configFilters?.filters;
    }
  }
);

export const getSerpLiteConfigFilters = createSelector(
  getConfigFilters,
  (configFilters) => {
    if (configFilters) {
      return configFilters.filter(configFilter => {
        return configFilter.facet === 'field_specialty_ids' || configFilter.facet === 'expertise_codes'
      })
    }
  }
);

export const getBDCConfigFilters = createSelector(
  getConfigFilters,
  (configFilters) => {
    if (configFilters) {
      return configFilters.find(configFilter => {
        return configFilter.facet === 'bdc_type_codes'
      })
    }
  }
);

export const selectConfigFilters = createSelector(
  selectSearchFilters,
  (state) => state.configFilters
);
