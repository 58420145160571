import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LozengeComponent } from './lozenge/lozenge.component';
import { ThemeLozengeComponent } from './theme-lozenge/theme-lozenge.component';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  imports: [CommonModule, DlsIconModule],
  declarations: [LozengeComponent, ThemeLozengeComponent],
  exports: [ThemeLozengeComponent],
})
export class DlsLozengeModule {}
