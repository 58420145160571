import { createAction, props } from '@ngrx/store';
import { SearchParams } from '@interfaces/search-params.model';
import { SearchFilterV2 } from '@interfaces/search-filter-v2.model';

export enum SearchFiltersV2ActionsTypes {
  requestSettingsSearchFilters = '[GET] V2 Get new search filters from config',
  requestSettingsSearchFiltersSuccess = '[GET] V2 Get new search filters from config success',
  requestSettingsSearchFiltersFailure = '[GET] V2 Get new search filters from config failed',
  requestSerpLiteFacets = '[GET] V2 request serp lite facets',
  requestSerpLiteFacetsFailure = '[GET] V2 request serp lite facets failed',
  setSearchFiltersWithSerpLiteFacetData = '[SET] V2 Set filters with serp lite facet data',
  requestFacets = '[GET] V2 request facets',
  requestFacetsFailure = '[GET] V2 request facets failed',
  setSearchFiltersWithFacetData = '[SET] V2 Set filters with facet data',
  requestBaseFacets = '[GET] V2 request base facets',
  requestBaseFacetsFailure = '[SET] V2 request base facets failed',
  setSearchFiltersWithBaseFacetData = '[SET] V2 Set filters with base facet data',
  setSelectedFilters = '[Set] V2 Set selected filters for search',
  resetSelectedFilters = '[Set] V2 Reset selected filters for search'
}

export const requestSettingsSearchFiltersFromConfig = createAction(
  SearchFiltersV2ActionsTypes.requestSettingsSearchFilters
);

export const requestSettingsSearchFiltersFromConfigSuccess = createAction(
  SearchFiltersV2ActionsTypes.requestSettingsSearchFiltersSuccess,
  props<{ filters: SearchFilterV2[] }>()
);

export const requestSettingsSearchFiltersFromConfigFailure = createAction(
  SearchFiltersV2ActionsTypes.requestSettingsSearchFiltersFailure,
  props<{ error: any }>()
);

export const requestSerpLiteFacets = createAction(
  SearchFiltersV2ActionsTypes.requestSerpLiteFacets,
  props<{ search_params: SearchParams }>()
);

export const requestSerpLiteFacetsFailure = createAction(
  SearchFiltersV2ActionsTypes.requestSerpLiteFacetsFailure,
  props<{ error: TypeError }>()
);

export const setSearchFiltersWithSerpLiteFacetData = createAction(
  SearchFiltersV2ActionsTypes.setSearchFiltersWithSerpLiteFacetData,
  props<{ appliedFilters: SearchFilterV2[] }>()
);

export const requestFacets = createAction(
  SearchFiltersV2ActionsTypes.requestFacets,
  props<{ search_params: SearchParams }>()
);

export const requestFacetsFailure = createAction(
  SearchFiltersV2ActionsTypes.requestFacetsFailure,
  props<{ error: TypeError }>()
);

export const setSearchFiltersWithFacetData = createAction(
  SearchFiltersV2ActionsTypes.setSearchFiltersWithFacetData,
  props<{ appliedFilters: SearchFilterV2[] }>()
);

// SEARCH PARAMS WITH NO USER SELECTED FILTERS APPLIED
export const requestBaseFacets = createAction(
  SearchFiltersV2ActionsTypes.requestBaseFacets,
  props<{ search_params: SearchParams, radiusExpanded?: boolean }>()
);

export const requestBaseFacetsFailure = createAction(
  SearchFiltersV2ActionsTypes.requestBaseFacetsFailure,
  props<{ error: TypeError }>()
);

export const setSearchFiltersWithBaseFacetData = createAction(
  SearchFiltersV2ActionsTypes.setSearchFiltersWithBaseFacetData,
  props<{ baseFilters: SearchFilterV2[] }>()
);

export const setSelectedFilters = createAction(
  SearchFiltersV2ActionsTypes.setSelectedFilters,
  props<{ selectedFilters: any }>()
);

export const resetSelectedFilters = createAction(
  SearchFiltersV2ActionsTypes.resetSelectedFilters
);
