import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MsaRouting } from './msa.routes';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MsaFormComponent } from './msa.form.component';
import { MsaSettingsService } from './msa.settings.service';
import { GlobalHeaderSettings } from '../global-header/global-header-settings.class';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DlsCardModule } from '@zelis/dls/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DlsButtonModule } from '@zelis/dls/button';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [
    CommonModule,
    MsaRouting,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DlsCardModule,
    MatFormFieldModule,
    MatInputModule,
    DlsButtonModule,
    MatSelectModule,
  ],
  providers: [MsaSettingsService, GlobalHeaderSettings],
  declarations: [MsaFormComponent],
})
export class MsaModule {}
