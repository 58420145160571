<div class="incompatible-browser-overlay">
  <mat-dialog-content>
    <h3 class="text-center pad top-2x bottom-2x">
      {{ 'app_incompatible_browser_headline' | translate }}
    </h3>

    <div class="text-center">
      {{ 'app_incompatible_browser_body' | translate }}
    </div>

    <h5 class="text-medium text-center pad top-6x bottom-3x">
      {{ 'app_incompatible_browser_download_prompt' | translate }}
    </h5>

    <div class="text-center">
      <a
        href="https://support.microsoft.com/en-us/help/17621/internet-explorer-downloads"
        target="_blank"
      >
        <zelis-button
          tabindex="-1"
          variant="flat"
          color="accent"
        >
          {{ 'app_incompatible_browser_download' | translate }}
        </zelis-button>
      </a>
    </div>

    <div class="text-center pad top-3x bottom-4x">
      <a tabindex="0" (click)="homeClick()" (keydown.enter)="homeClick()">{{
        'app_incompatible_browser_homepage' | translate
      }}</a>
    </div>
  </mat-dialog-content>
</div>
