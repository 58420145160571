import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { UuxNetwork } from '@interfaces/uux-network.model';
import { NetworkSelectionWizardService } from '@services/network-selection-wizard/network-selection-wizard.service';
import { RouteUtilities } from '@utilities/route.utilities';
import { StorageUtilities } from '@utilities/storage.utilities';
import { Subscription } from 'rxjs';

interface GroupedNetworks {
  letter: string;
  networkList: UuxNetwork[];
}

@Component({
  selector: 'app-network-selection-dropdown',
  templateUrl: './network-selection-dropdown.component.html',
  styleUrls: ['./network-selection-dropdown.component.scss'],
})
export class NetworkSelectionDropdownComponent implements OnInit, OnDestroy {
  @Input() appearance: string = 'outline';
  @Input() darkTheme?: boolean = false;
  // 'openDropdown' is needed ONLY for custom triggers (ex: the 'Network' button on Mobile SERP)
  @Input() set openDropdown(open: boolean) { open && this.networkDropdown.openMenu(); };

  @Output() openDropdownChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public networkSelectionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('dropdownTrigger') networkDropdown: MatMenuTrigger;
  public allNetworksExpanded: boolean = false;
  public allNetworks: GroupedNetworks[];
  public selectedNetwork: UuxNetwork;
  public similarNetworks: UuxNetwork[];
  private subscriptions: Subscription = new Subscription();
  private routeUtilities = new RouteUtilities();
  private urlDirectAccess: boolean = true;

  constructor(
    private networkSelectionWizardService: NetworkSelectionWizardService,
    private storage: StorageUtilities,
  ) {}

  ngOnInit(): void {
    this.listenForNetworkChange();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onCloseMenu(): void {
    this.openDropdownChange.next(false);
    this.networkDropdown.closeMenu();
    window.scroll(0,0);
  }

  public onOpenNetworkWizard(): void {
    this.networkSelectionWizardService.openNetworkSelectWizard(true);
    this.onCloseMenu();
  }

  public onSelectNetwork(
    network: UuxNetwork,
    closeMenu = true,
    urlDirectAccess = false
  ): void {
    this.selectedNetwork = network;
    this.urlDirectAccess = urlDirectAccess;
    if (!this.urlDirectAccess)
      this.storage.sessionStorageSet('wizardActions', true);
    this.networkSelectionWizardService.saveSelectedPlan(network);
    this.networkSelectionWizardService.setSelections();
    this.networkSelectionChange.emit(true);
    if (closeMenu) {
      this.onCloseMenu();
    }
  }

  private listenForNetworkChange(): void {
    // Update selected-network in the "network-select field" &
    // populate dropdown with Similar Networks (networks from the same plan-list)
    this.subscriptions.add(
      this.networkSelectionWizardService.listenToNetworkChange()
        .subscribe((network: UuxNetwork) => {
          const isReturnUser = this.networkSelectionWizardService.isReturnUser;
          const { ci } = this.routeUtilities.getParamsFromUrl();
          const inWizard = this.storage.sessionStorageGet('wizardPlanListPage');
          this.selectedNetwork = network;
          if (
            !this.selectedNetwork ||
            (this.selectedNetwork.ci !== ci &&
              !inWizard &&
              this.urlDirectAccess &&
              !isReturnUser)
          ) {
            this.storage.sessionStorageRemove('wizardActions');
            this.createSelectedNetworkFromParams();
            return;
          }
          this.getSimilarNetworks();
          this.getAllNetworks();
      })
    );
  }

  private getSimilarNetworks(): void {
    const wizardActions = this.storage.sessionStorageGet('wizardActions');
    this.similarNetworks = wizardActions
      ? this.storage.sessionStorageGet('wizardPlans')
      : [this.selectedNetwork];
  }

  private getAllNetworks(): void {
    // Plans are fetched from the Marketing Plans List
    this.subscriptions.add(
      this.networkSelectionWizardService
        .listenToAllNetworksChange()
        .subscribe((allPlans: UuxNetwork[]) => {
          this.groupNetworksAlphabetically(allPlans);
        })
    );
  }

  private groupNetworksAlphabetically(networks: UuxNetwork[]): void {
    // Group networks alphabetically by the first letter
    this.allNetworks = networks?.reduce((acc: GroupedNetworks[], curr: UuxNetwork) => {
      const idx: number = acc.findIndex((network: GroupedNetworks) => network.letter === curr.displayName[0]);
      if (idx === -1) {
        acc.push({ letter: curr.displayName[0], networkList: [curr] });
      }
      else {
        acc[idx].networkList.push(curr);
        acc[idx].networkList.sort((network1, network2) => network1.displayName > network2.displayName ? 1 : -1);
      }
      return acc;
    }, [])
    .sort((letterGroup1, letterGroup2) => letterGroup1.letter > letterGroup2.letter ? 1 : -1);
  }

  private createSelectedNetworkFromParams(): void {
    const { ci, corp_code, locale } = this.routeUtilities.getParamsFromUrl();
    this.networkSelectionWizardService
      .getPlans({
        corp_code: corp_code,
        locale: locale,
      })
      .subscribe((plans: UuxNetwork[]) => {
        const matchingPlan = plans.find((plan) => plan.ci === ci);
        this.onSelectNetwork(matchingPlan, false, true);
      });
  }
}
