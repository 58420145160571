import { Results } from '@interfaces/results.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const SelectRatesSummaryState =
  createFeatureSelector<Results>('serp-summary');

export const SelectRatesSummary = createSelector(
  SelectRatesSummaryState,
  (state: Results) => {
    return state;
  }
);

export const SelectRatesSummaryLoading = createSelector(
  SelectRatesSummaryState,
  (state: Results) => {
    return state.loading;
  }
);

export const SelectRatesSummaryMeta = createSelector(
  SelectRatesSummaryState,
  (state: Results) => {
    return state._meta;
  }
);

export const SelectRatesSummaryProviders = createSelector(
  SelectRatesSummaryState,
  (state: Results) => {
    return state.providers;
  }
);

export const SelectRatesSummaryCacheStatus = createSelector(
  SelectRatesSummaryMeta,
  (meta) => {
    return meta?.cache_status;
  }
);

export const SelectRatesSummaryConfirmationCode = createSelector(
  SelectRatesSummaryMeta,
  (meta) => {
    return meta?.confirmation_code;
  }
);
