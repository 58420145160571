import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './search-filters-v2.reducer';
import { SearchFiltersV2Effects } from './search-filters-v2.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('search_filters_v2', reducer),
    EffectsModule.forFeature([SearchFiltersV2Effects]),
  ],
})
export default class SearchFiltersV2Module {}
