import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { MissingTranslationCustomHandler } from '@services/translations/missing-translation.handler';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { AppComponent } from './app.component';
import { Routing, AppRoutingModule } from './app-routing.module';
import { DlsProgressBarModule } from '@zelis/dls/progress-bar';
import {
  AnalyticsModule,
  GlobalFooterModule,
  PageContainerModule,
} from '@zelis/platform-ui-components';
import { MsaBannerComponent } from '@components/+msa/msa.banner.component';
import { NotificationOverlayModule } from '@components/notification-overlay';
import { IncompatibleBrowserOverlayModule } from '@components/incompatible-browser-overlay';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { MobileAutosuggestOverlayModule } from '@components/mobile-autosuggest-overlay';
import { MobileHeaderOverlayModule } from '@components/mobile-header-overlay/mobile-header-overlay.module';
import { BrowserDetectModule } from '@services/browser-detect/browser-detect.module';
import { SkipLinksModule } from '@components/skip-link/skip-links.module';
import { MsaModule } from '@components/+msa/msa.module';
import { BvaModule } from '@components/+msa/bva/bva.module';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomErrorHandler } from '@interceptors/error.handler.interceptor';
import { RollbarService } from '@interceptors/error.handler.interceptor';
import Rollbar from 'rollbar';
import { ChatModule } from '@components/pat-chat/chat/chat.module';
import { StickyContactFabModule } from '@components/sticky-contact-fab';
import { MetaInformationModule } from '@components/meta-information/meta-information.module';
import { HttpAppInterceptor } from '@interceptors/http.app.interceptor';
import { GpsErrorOverlayModule } from '@components/gps-error-overlay/gps-error-overlay.module';
import { GatedEntryOverlayModule } from '@components/gated-entry-overlay/gated-entry-overlay.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { GlobalHeaderModule } from '@components/global-header/global-header.module';
import { HierarchyListModule } from '@components/hierarchy-list/hierarchy-list.module';
import { environment } from '../environments/environment';
import {
  NgxPageScrollCoreModule,
  PageScrollService,
} from 'ngx-page-scroll-core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DlsIconModule } from '@zelis/dls/icons';
import { GuidedSearchDialogModule } from '@components/guided-search/guided-search-dialog/guided-search-dialog.module';
import { HttpConfigSignatureInterceptor } from '@interceptors/http.config-signature.interceptor';
import { DialogService } from '@zelis/dls/dialog';
import { MaterialCssVarsModule } from 'angular-material-css-vars';
import { RootStoreModule } from './root-store/root-store.module';
import { EffectsModule } from '@ngrx/effects';
import { AppConfigService } from '@services/app.config.service';
import { Observable } from 'rxjs';
import { AppConfig } from '@interfaces/app-config.model';
import { NetworkSelectionWizardModule } from '@components/network-selection-wizard/network-selection-wizard.module';
import { DlsButtonModule } from '@zelis/dls/button';
import { SearchFiltersV2Service } from '@services/search/search-filters-v2.service';

const initializeAppFactory = (
  appConfigService: AppConfigService
): (() => Observable<AppConfig>) => {
  return () => appConfigService.config;
};

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/translations/', '.json');
};

export const rollbarFactory = () => {
  // Rollbar configured in src/app/interceptors/error.handler.interceptor.ts
  return new Rollbar();
};

@NgModule({
  declarations: [AppComponent, MsaBannerComponent],
  imports: [
    Routing,
    BrowserAnimationsModule,
    environment.e2e ? NoopAnimationsModule : [],
    FlexLayoutModule,
    AppRoutingModule,
    BrowserModule,
    SkipLinksModule,
    FormsModule,
    MaterialCssVarsModule.forRoot({
      // all optional
      isAutoContrast: true,
      darkThemeClass: 'dark-theme',
      lightThemeClass: 'light-theme',
      // ...
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationCustomHandler,
      },
    }),
    AnalyticsModule,
    GlobalHeaderModule,
    GlobalFooterModule,
    HttpClientModule,
    NotificationOverlayModule,
    IncompatibleBrowserOverlayModule,
    MobileAutosuggestOverlayModule,
    MobileHeaderOverlayModule,
    BrowserDetectModule,
    PageContainerModule,
    MsaModule,
    BvaModule,
    ChatModule,
    StickyContactFabModule,
    MetaInformationModule,
    GpsErrorOverlayModule,
    GatedEntryOverlayModule,
    NetworkSelectionWizardModule,
    HierarchyListModule,
    MatBottomSheetModule,
    environment.e2e
      ? NgxPageScrollCoreModule.forRoot({ duration: 0 })
      : NgxPageScrollCoreModule.forRoot(),
    HammerModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'APM Demo App DevTools',
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    RootStoreModule,
    DlsIconModule,
    GuidedSearchDialogModule,
    DlsButtonModule,
    DlsProgressBarModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [AppConfigService],
    },
    TranslateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigSignatureInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAppInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    {
      provide: RollbarService,
      useFactory: rollbarFactory,
    },
    PageScrollService,
    DialogService,
    SearchFiltersV2Service,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
